import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Button } from '../components/ui/button';
import { Card, CardContent } from '../components/ui/card';
import {
  Users,
  LineChart,
  Lock,
  Target,
  ArrowUpCircle,
  Activity,
  Check,
  BarChart,
  ArrowRight,
  RefreshCw,
} from 'lucide-react';

const UnauthLanding = () => {
  const auth = useAuth();

  return (
    <div className="min-h-screen bg-[#f4f2ed]">
      {/* Navigation */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img src="/logo.png" alt="Logo" className="h-8 w-auto" />
            </div>
            <div className="flex items-center">
              <Button
                onClick={() => auth.signinRedirect()}
                className="bg-[#1f513f] hover:bg-[#f4f2ed] hover:text-[#1f513f] text-white border border-[#1f513f]"
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </nav>

      <div className="relative w-full bg-[#f4f2ed] overflow-hidden">
        {/* Background SVG container - make it span full width */}
        <div
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: 'url(/contour-lines.svg)',
            backgroundRepeat: 'repeat',
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
          }}
        />

        {/* Content container */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-24 relative z-10">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1 className="text-4xl font-bold text-[#20201d] sm:text-5xl md:text-6xl">
                Consumption Growth Platform for Usage-Driven Sales Teams
              </h1>
              <p className="mt-6 text-xl text-[rgba(66,59,47,0.75)]">
                Belay reimagines how B2B technology companies drive and measure success in a
                consumption-based world. Move beyond "Closed Won" to ongoing customer growth.
              </p>
              <div className="mt-10">
                <Button
                  onClick={() => auth.signinRedirect()}
                  className="w-full sm:w-auto bg-[#1f513f] hover:bg-[#f4f2ed] hover:text-[#1f513f] text-white border border-[#1f513f] px-8 py-4 text-lg"
                >
                  Get Started
                </Button>
              </div>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-6">
              <div className="bg-white rounded-lg shadow-xl p-3">
                <img
                  src="/dashboard-preview.svg"
                  alt="Dashboard preview"
                  className="rounded-lg shadow-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-[#20201d]">
              The Operating System for Consumption-Based Businesses
            </h2>
          </div>
          <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <Card>
              <CardContent className="pt-6">
                <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
                  <Target className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-[#20201d]">
                  Maturity-Based Consumption Tracking
                </h3>
                <p className="mt-4 text-[rgba(66,59,47,0.75)]">
                  Build tailored maturity "levels" that map directly to how your product is adopted,
                  enabling your team to proactively drive expansion.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-6">
                <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
                  <Users className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-[#20201d]">Customer Collaboration Hub</h3>
                <p className="mt-4 text-[rgba(66,59,47,0.75)]">
                  Unlike traditional CRMs, Belay is built to be shared between vendor and customer,
                  aligning on use cases, blockers, and strategic recommendations.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-6">
                <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
                  <BarChart className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-[#20201d]">Business Value Hub</h3>
                <p className="mt-4 text-[rgba(66,59,47,0.75)]">
                  Replace static QBR decks with a living, dynamic view that constantly maps your
                  partnership to the business metrics and value it delivers.
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      <div className="bg-[#1f513f] text-white py-24 relative overflow-hidden">
        <div className="absolute inset-0 opacity-10"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
            <div>
              <h2 className="text-3xl font-bold">We're in the Consumption Era</h2>
              <p className="mt-6 text-lg text-white/90">
                The shift from deal-based selling to consumption-based selling has fundamentally
                disrupted go-to-market strategies. Success is no longer measured by "Closed Won" or
                "Closed Lost" – it's an ongoing process that requires new tools and approaches.
              </p>
              <div className="mt-12 grid grid-cols-1 gap-6">
                <div className="flex items-start gap-4">
                  <div className="rounded-lg p-2 bg-white/10">
                    <ArrowUpCircle className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-semibold">Changing Business Models</h3>
                    <p className="mt-2 text-white/80">
                      B2B technology has evolved: Hardware → Licensed Software → SaaS → Consumption.
                      The focus is no longer on closing a deal, but unlocking and expanding usage
                      over time.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="rounded-lg p-2 bg-white/10">
                    <RefreshCw className="w-6 h-6" />
                  </div>
                  <div>
                    <h3 className="font-semibold">New Challenges</h3>
                    <p className="mt-2 text-white/80">
                      Both vendors and customers struggle with outdated tools built for an era of
                      one-time transactions, not ongoing relationships and value creation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 lg:mt-0">
              <div className="relative">
                <img src="/climbers.png" alt="Climbing illustration" className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f4f2ed] py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
            <div>
              <h2 className="text-3xl font-bold text-[#20201d]">
                Traditional Sales Tools Are Built for the Wrong Economy
              </h2>
              <div className="mt-6 space-y-6">
                <div className="flex items-start gap-4">
                  <div className="bg-[#1f513f] p-3 rounded-lg">
                    <ArrowRight className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-xl">
                      Consumption is a Journey, Not a Transaction
                    </h3>
                    <p className="mt-2 text-[rgba(66,59,47,0.75)]">
                      CRMs track transactions, but real value comes from orchestrating ongoing
                      customer success, not just monitoring it.
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="bg-[#1f513f] p-3 rounded-lg">
                    <LineChart className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-xl">
                      From Reporting to Affecting Consumption
                    </h3>
                    <p className="mt-2 text-[rgba(66,59,47,0.75)]">
                      Belay is designed to drive proactive outcomes, not just report past activity.
                      The question isn't "How do we forecast consumption?" but "How do we affect
                      consumption?"
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="bg-[#1f513f] p-3 rounded-lg">
                    <Lock className="h-6 w-6 text-white" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-xl">Structured, Scalable Playbooks</h3>
                    <p className="mt-2 text-[rgba(66,59,47,0.75)]">
                      Turn frontline insights from your field teams into structured, scalable
                      playbooks for expansion - your most underutilized asset in sales.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 lg:mt-0">
              <img
                src="/transformation.png"
                alt="Transformation illustration"
                className="rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Level Up Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#20201d]">Sales Is Project Management</h2>
            <p className="mt-4 text-xl text-[rgba(66,59,47,0.75)]">
              The path to expansion starts with the first successful workload. Under-consuming
              customers do not renew or expand. Belay helps you orchestrate customer success.
            </p>
          </div>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <Card>
              <CardContent className="pt-6">
                <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
                  <Target className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold">AE Cockpit</h3>
                <p className="mt-4 text-[rgba(66,59,47,0.75)]">
                  We use AI to monitor all incoming data sources - email, Slack, Jira, and more - to
                  keep Belay updated automatically and sync with your back-office tools.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-6">
                <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
                  <ArrowUpCircle className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold">Automated Expansion Playbooks</h3>
                <p className="mt-4 text-[rgba(66,59,47,0.75)]">
                  AI-driven insights suggest the best next steps for increasing customer maturity,
                  with data-backed recommendations for driving adoption.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="pt-6">
                <div className="h-12 w-12 rounded-lg bg-[#1f513f] flex items-center justify-center mb-4">
                  <Activity className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold">Proactive Risk Identification</h3>
                <p className="mt-4 text-[rgba(66,59,47,0.75)]">
                  Don't wait for consumption drops to trigger a reaction. Belay predicts churn risks
                  early and surfaces playbooks for timely intervention.
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {/* Customer Alignment Section */}
      <div className="bg-[#f4f2ed] py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
            <div className="order-2">
              <h2 className="text-3xl font-bold text-[#20201d]">Business Impact</h2>
              <p className="mt-6 text-lg text-[rgba(66,59,47,0.75)]">
                Companies that adopt Belay can expect to see measurable results across their
                business
              </p>
              <div className="mt-8 space-y-6">
                <div className="flex items-center gap-4">
                  <Check className="h-6 w-6 text-[#1f513f]" />
                  <p>Higher Net Revenue Retention (NRR) through proactive expansion paths</p>
                </div>
                <div className="flex items-center gap-4">
                  <Check className="h-6 w-6 text-[#1f513f]" />
                  <p>Faster customer onboarding and adoption with clear, guided pathways</p>
                </div>
                <div className="flex items-center gap-4">
                  <Check className="h-6 w-6 text-[#1f513f]" />
                  <p>Reduced churn and revenue volatility with predictive risk insights</p>
                </div>
                <div className="flex items-center gap-4">
                  <Check className="h-6 w-6 text-[#1f513f]" />
                  <p>Elimination of "shadow CRMs" and inefficient spreadsheets</p>
                </div>
              </div>
            </div>
            <div className="order-1 mb-12 lg:mb-0">
              <img
                src="/customer-alignment.svg"
                alt="Customer alignment illustration"
                className="rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-[#1f513f] py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white">Get Ahead in the Consumption Economy</h2>
          <p className="mt-4 text-xl text-white/80">
            Companies that embrace this shift early will outgrow their competitors, increase
            customer value, and define the future of B2B sales.
          </p>
          <div className="mt-8">
            <Button
              onClick={() => auth.signinRedirect()}
              className="bg-white text-[#1f513f] hover:bg-[#f4f2ed] px-8 py-4 text-lg"
            >
              Start Your Journey
            </Button>
          </div>
        </div>
      </div>

      <footer className="bg-[#1f513f] text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-4">
            <Link to="/terms" className="text-sm text-white hover:text-gray-300">
              Terms & Conditions
            </Link>
            <span className="text-white/50">|</span>
            <Link to="/privacy" className="text-sm text-white hover:text-gray-300">
              Privacy Policy
            </Link>
            <span className="text-white/50">|</span>
            <button onClick={() => {}} className="text-sm text-white hover:text-gray-300">
              Support
            </button>
          </div>
          <div className="mt-8 border-t border-white/10 pt-8">
            <p className="text-sm text-center text-gray-300">© 2025 Belay. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UnauthLanding;
