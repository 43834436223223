// services/metricsApi.js
import api from './api';

export const metricsApi = {
  getMetrics: async businessCaseId => {
    if (!businessCaseId) {
      throw new Error('Business case ID is required');
    }
    const response = await api.get(`/metrics/business-case/${businessCaseId}/metrics`);
    return response.data;
  },

  createMetric: async (businessCaseId, metricData) => {
    if (!businessCaseId) {
      throw new Error('Business case ID is required');
    }
    const response = await api.post(`/metrics/business-case/${businessCaseId}/metrics`, metricData);
    return response.data;
  },

  updateMetric: async (metricId, updates) => {
    if (!metricId) {
      throw new Error('Metric ID is required');
    }
    const response = await api.patch(`/metrics/metrics/${metricId}`, updates);
    return response.data;
  },

  deleteMetric: async metricId => {
    if (!metricId) {
      throw new Error('Metric ID is required');
    }
    await api.delete(`/metrics/metrics/${metricId}`);
  },

  getAccountMetrics: async accountId => {
    if (!accountId) {
      throw new Error('Account ID is required');
    }
    const response = await api.get(`/metrics/account/${accountId}/metrics`);
    return response.data;
  },

  getBoardMetrics: async boardId => {
    if (!boardId) {
      throw new Error('Board ID is required');
    }
    const response = await api.get(`/metrics/board/${boardId}/metrics`);
    return response.data;
  },

  getWorkstreamMetrics: async (accountId, type) => {
    if (!accountId) {
      throw new Error('Account ID is required');
    }
    const url = type
      ? `/metrics/account/${accountId}/workstream-metrics?type=${type}`
      : `/metrics/account/${accountId}/workstream-metrics`;

    try {
      const response = await api.get(url);
      console.log('Workstream metrics response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching workstream metrics:', error);
      throw error;
    }
  },

  getStatusSummary: async accountId => {
    if (!accountId) {
      throw new Error('Account ID is required');
    }
    const response = await api.get(`/metrics/account/${accountId}/status-summary`);
    return response.data;
  },
};
