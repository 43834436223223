import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Card } from '../components/ui/card';
import { Alert, AlertDescription } from '../components/ui/alert';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Sector } from 'recharts';
import { metricsApi } from '../services/metricsApi';
import { AlertCircle } from 'lucide-react';

const COLORS = {
  revenue: {
    realized: 'hsl(154 45% 22%)',
    potential: 'hsla(154 45% 22%, 0.3)', // Transparent version
  },
  cost: {
    realized: 'hsl(217 48% 48%)',
    potential: 'hsla(217 48% 48%, 0.3)', // Transparent version
  },
};

// Secondary colors from the design system
const SUPPLEMENTARY_COLORS = [
  'hsl(16 47% 49%)', // Desert Rock
  'hsl(39 50% 48%)', // Autumn Leaf
  'hsl(210 45% 64%)', // Sky
  'hsl(16 47% 69%)', // Canyon
  'hsl(39 65% 73%)', // Morning Sun
];

// Create transparent versions of supplementary colors
const TRANSPARENT_SUPPLEMENTARY_COLORS = SUPPLEMENTARY_COLORS.map(color => {
  const match = color.match(/hsl\((\d+)\s+(\d+)%\s+(\d+)%\)/);
  if (match) {
    const [, h, s, l] = match;
    return `hsla(${h}, ${s}%, ${l}%, 0.3)`;
  }
  return color;
});

const RADIAN = Math.PI / 180;

// Enhanced active shape renderer for Tableau-like effect
const renderActiveShape = props => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5} // Make active sector larger
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        stroke={fill}
        strokeWidth={2}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 8}
        outerRadius={outerRadius + 12}
        fill={fill}
      />
    </g>
  );
};

const WorkstreamMetrics = ({ accountId }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onPieLeave = useCallback(() => {
    setActiveIndex(null);
  }, [setActiveIndex]);

  const {
    data: metrics,
    isLoading,
    error,
  } = useQuery(
    ['workstream-metrics', accountId],
    () => metricsApi.getWorkstreamMetrics(accountId),
    {
      enabled: Boolean(accountId),
    }
  );

  const validateMetrics = metricsData => {
    if (!Array.isArray(metricsData)) {
      console.error('Metrics is not an array:', metricsData);
      return [];
    }

    return metricsData.filter(metric => {
      if (!metric) {
        console.error('Found undefined metric');
        return false;
      }

      if (!metric.type || !COLORS[metric.type]) {
        console.error('Invalid metric type:', metric);
        return false;
      }

      return true;
    });
  };

  if (isLoading) {
    return (
      <Card className="p-4">
        <div className="h-64 flex items-center justify-center">
          <p className="text-gray-500">Loading metrics...</p>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>Error loading metrics: {error.message}</AlertDescription>
      </Alert>
    );
  }

  const validMetrics = validateMetrics(metrics);

  if (!validMetrics.length) {
    return (
      <Card className="p-4">
        <div className="h-64 flex items-center justify-center">
          <p className="text-gray-500">No valid metrics available</p>
        </div>
      </Card>
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-3 border rounded shadow">
          <p className="font-medium">{data.workloadName}</p>
          <div className="space-y-1 mt-2">
            <p className="text-sm">
              <span className="font-medium">Value: </span>${(data.value / 1000000).toFixed(1)}M
            </p>
            <p className="text-sm">
              <span className="font-medium">Status: </span>
              {data.isRealized ? 'Realized' : 'Potential'}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
    workloadName,
  }) => {
    const radius = outerRadius * 1.2;
    const x1 = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
    const y1 = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
    const x2 = cx + radius * Math.cos(-midAngle * RADIAN);
    const y2 = cy + radius * Math.sin(-midAngle * RADIAN);
    const textAnchor = x2 > cx ? 'start' : 'end';
    const formattedValue = `$${(value / 1000000).toFixed(1)}M`;

    return (
      <g>
        <path
          d={`M${x1},${y1}L${x2},${y2}`}
          stroke={COLORS[validMetrics[0].type].realized}
          strokeWidth={1}
          fill="none"
        />
        <text
          x={x2 + (x2 > cx ? 5 : -5)}
          y={y2}
          textAnchor={textAnchor}
          dominantBaseline="central"
          className="text-xs fill-current font-medium"
        >
          {formattedValue}
        </text>
      </g>
    );
  };

  return (
    <div className="h-64">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={validMetrics}
            dataKey="value"
            nameKey="workloadName"
            cx="50%"
            cy="50%"
            outerRadius={100}
            paddingAngle={0}
            label={renderCustomizedLabel}
            labelLine={true}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {validMetrics.map((entry, index) => {
              const color = COLORS[entry.type];
              if (!color) {
                console.error('No color config for type:', entry.type);
                return null;
              }

              // Decide whether to use main colors or supplementary colors based on index
              let fillColor;

              if (entry.isRealized) {
                // For realized metrics, use full opacity colors
                fillColor =
                  index < 2
                    ? color.realized
                    : SUPPLEMENTARY_COLORS[(index - 2) % SUPPLEMENTARY_COLORS.length];
              } else {
                // For potential metrics, use transparent colors
                fillColor =
                  index < 2
                    ? color.potential
                    : TRANSPARENT_SUPPLEMENTARY_COLORS[
                        (index - 2) % TRANSPARENT_SUPPLEMENTARY_COLORS.length
                      ];
              }

              return (
                <Cell
                  key={`cell-${index}`}
                  fill={fillColor}
                  stroke={entry.isRealized ? fillColor : '#e11d48'} // Red outline for potential
                  strokeWidth={entry.isRealized ? 1 : 1.5}
                />
              );
            })}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WorkstreamMetrics;
