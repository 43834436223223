import React from 'react';
import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogClose
} from '../components/ui/dialog';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { useQueryClient } from 'react-query';
import { accountApi } from '../services/api';
import { useAuth } from "react-oidc-context";
import { refreshTokenSilently } from '../utils/auth';

export const CreateAccountDialog = ({ open, onOpenChange, parentAccountId }) => {
    const [name, setName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const queryClient = useQueryClient();
    const auth = useAuth();

    // Get current user's ID
    const currentUserId = auth.user?.profile["cognito:username"];
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');
      setIsLoading(true);
  
      try {
        await accountApi.createAccount({ 
          name, 
          createdBy: currentUserId,
          parentAccount: parentAccountId // Add parent account ID if provided
        });
        await refreshTokenSilently(auth); // Refresh token to add the group to the token
        queryClient.invalidateQueries('accounts');
        onOpenChange(false);
        setName('');
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to create account');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-md bg-white">
          <DialogHeader className="relative pb-4 border-b">
            <DialogTitle className="text-xl font-semibold text-gray-900">Create New Account</DialogTitle>
            <DialogDescription className="text-gray-500 mt-1">
              Create a new account to manage tasks and boards
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4 pt-4">
            <div className="space-y-2">
              <Label htmlFor="name" className="text-sm font-medium text-gray-700">
                Account Name
              </Label>
              <Input
                id="name"
                placeholder="Enter account name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="w-full border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            {error && (
              <div className="text-sm text-red-500">{error}</div>
            )}
            <div className="flex justify-end space-x-2 pt-4 border-t">
              <DialogClose asChild>
                <Button variant="outline" type="button">
                  Cancel
                </Button>
              </DialogClose>
              <Button 
                type="submit" 
                disabled={isLoading}
              >
                {isLoading ? 'Creating...' : 'Create Account'}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  export default CreateAccountDialog;