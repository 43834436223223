class TokenService {
  constructor() {
    this.token = null;
    this.refreshPromise = null;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  clearToken() {
    this.token = null;
  }

  setRefreshPromise(promise) {
    this.refreshPromise = promise;
  }

  getRefreshPromise() {
    return this.refreshPromise;
  }
}

const tokenService = new TokenService();
export default tokenService;

