import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DragDropContext } from '@hello-pangea/dnd';
import {
  LayoutDashboard,
  Users,
  Briefcase,
  Lightbulb,
  Filter,
  Plus,
  ChevronDown,
  ChevronRight
} from 'lucide-react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { cn } from '../lib/utils';
import Column from '../components/Board/Column';
import { boardApi, taskApi } from '../services/api';

const TasksPage = () => {
  const { accountId, type, boardId } = useParams();
  const navigate = useNavigate();

  const [expandedItems, setExpandedItems] = useState(new Set(['all']));
  const [searchTerm, setSearchTerm] = useState('');
  const [tasks, setTasks] = useState({});
  const [boards, setBoards] = useState([]);
  const [columns, setColumns] = useState([]);
  const [boardsByType, setBoardsByType] = useState({});
  const [activeTaskType, setActiveTaskType] = useState(type || 'all');
  const [activeBoardId, setActiveBoardId] = useState(boardId || null);
  const [isLoading, setIsLoading] = useState(true);

  // Load boards when component mounts
  useEffect(() => {
    const loadBoards = async () => {
      if (!accountId) return;

      try {
        setIsLoading(true);
        const boardsData = await boardApi.getAllBoards({ accountId });

        if (!boardsData || !Array.isArray(boardsData)) {
          console.error('Invalid boards data received:', boardsData);
          setBoards([]);
          setBoardsByType({});
        } else {
          setBoards(boardsData);

          // Group boards by type
          const groupedBoards = boardsData.reduce((acc, board) => {
            const boardType = board.type || 'Other';
            if (!acc[boardType]) acc[boardType] = [];
            acc[boardType].push(board);
            return acc;
          }, {});

          setBoardsByType(groupedBoards);
        }
      } catch (error) {
        console.error('Error loading boards:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadBoards();
  }, [accountId]);

  // Load columns when board or type changes
  useEffect(() => {
    const loadColumns = async () => {
      // Set default columns initially to avoid undefined columns
      const defaultColumns = [
        { id: 'to-start', title: 'To Start', order: 0 },
        { id: 'in-progress', title: 'In Progress', order: 1 },
        { id: 'on-customer', title: 'On Customer', order: 2 },
        { id: 'blocked', title: 'Blocked', order: 3 },
        { id: 'completed', title: 'Completed', order: 4 }
      ];

      setColumns(defaultColumns);

      if (!activeBoardId) return;

      try {
        const board = await boardApi.getBoardById(activeBoardId);
        if (board && board.columns && board.columns.length > 0) {
          setColumns(board.columns);
        }
      } catch (error) {
        console.error('Error loading columns:', error);
      }
    };

    loadColumns();
  }, [activeBoardId]);

  // Load tasks when filters change
  useEffect(() => {
    const loadTasks = async () => {
      if (!accountId || columns.length === 0) return;

      try {
        // Initialize tasks with empty columns
        const initialTasks = columns.reduce((acc, column) => {
          acc[column.id] = [];
          return acc;
        }, {});

        let fetchedTasks = [];

        // Special case: When "Workloads" category is selected but no specific board
        if (activeTaskType === 'Workload' && !activeBoardId) {
          // Find all Workload boards
          const workloadBoards = boards.filter(board => board.type === 'Workload');

          if (workloadBoards.length > 0) {
            // Get tasks from each Workload board
            for (const board of workloadBoards) {
              const boardTasks = await taskApi.getAllTasks({
                accountId,
                board: board._id
              });
              fetchedTasks = [...fetchedTasks, ...boardTasks];
            }
          } else {
            // Fallback: If no Workload boards, just use type filter
            fetchedTasks = await taskApi.getAllTasks({
              accountId,
              type: activeTaskType
            });
          }
        } else {
          // Standard filtering
          const filters = { accountId };

          if (activeBoardId) {
            // When a board is selected, show all tasks for that board
            filters.board = activeBoardId;
          } else if (activeTaskType && activeTaskType !== 'all') {
            // When a category is selected (not "All"), filter by task type
            filters.type = activeTaskType;
          }

          fetchedTasks = await taskApi.getAllTasks(filters);
        }

        // Apply search filter if needed
        const filteredTasks = searchTerm
            ? fetchedTasks.filter(task =>
                task.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                task.description?.toLowerCase().includes(searchTerm.toLowerCase())
            )
            : fetchedTasks;

        // Organize tasks by column
        const organizedTasks = filteredTasks.reduce((acc, task) => {
          const status = task.status || columns[0]?.id;

          if (acc[status]) {
            acc[status].push({
              ...task,
              _id: String(task._id)
            });
          } else if (columns[0]?.id) {
            // If status doesn't match any column, put in first column
            acc[columns[0].id].push({
              ...task,
              _id: String(task._id)
            });
          }

          return acc;
        }, initialTasks);

        setTasks(organizedTasks);
      } catch (error) {
        console.error('Error loading tasks:', error);
      }
    };

    // Use a small delay to avoid excessive API calls during rapid changes
    const timer = setTimeout(() => {
      loadTasks();
    }, 100);

    return () => clearTimeout(timer);
  }, [accountId, activeTaskType, activeBoardId, columns, searchTerm, boards]);

  // Handle menu item expansion/collapse
  const toggleExpanded = (itemId) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };

  // Handle category or board selection
  const handleSelection = (type, boardId = null) => {
    setActiveTaskType(type);
    setActiveBoardId(boardId);

    // Update URL to reflect selection
    const newPath = boardId
        ? `/accounts/${accountId}/tasks/${type}/${boardId}`
        : `/accounts/${accountId}/tasks/${type}`;

    navigate(newPath);
  };

  // Handle drag and drop
  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;
    if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
    ) {
      return;
    }

    try {
      // Get the arrays of tasks from the source and destination columns
      const sourceArray = Array.isArray(tasks[source.droppableId])
          ? [...tasks[source.droppableId]]
          : [];
      const destArray = Array.isArray(tasks[destination.droppableId])
          ? [...tasks[destination.droppableId]]
          : [];

      // Find the task being moved
      const movedTask = sourceArray[source.index];
      if (!movedTask) return;

      // Remove task from source array
      const newSourceArray = [...sourceArray];
      newSourceArray.splice(source.index, 1);

      // Add task to destination array with updated status
      const updatedTask = {
        ...movedTask,
        status: destination.droppableId
      };

      const newDestArray = [...destArray];
      newDestArray.splice(destination.index, 0, updatedTask);

      // Update state optimistically
      setTasks(prevTasks => ({
        ...prevTasks,
        [source.droppableId]: newSourceArray,
        [destination.droppableId]: newDestArray
      }));

      // Update backend
      await taskApi.updateTask(draggableId, {
        status: destination.droppableId
      });
    } catch (err) {
      console.error('Error in drag and drop:', err);
    }
  };

  // Get page title
  const getPageTitle = () => {
    if (activeBoardId && boards.length > 0) {
      const selectedBoard = boards.find(board => board._id === activeBoardId);
      return selectedBoard?.name || 'Tasks';
    }

    switch (activeTaskType) {
      case 'all': return 'All Tasks';
      case 'Workload': return 'Workloads';
      default: return `${activeTaskType} Tasks`;
    }
  };

  if (isLoading) {
    return <div className="p-8">Loading task data...</div>;
  }

  return (
      <div className="flex h-screen">
        {/* Left Sidebar Navigation */}
        <div className="w-64 border-r p-4 bg-gray-50 overflow-y-auto">
          <h2 className="text-lg font-semibold mb-6">Task Views</h2>

          <div className="space-y-2">
            {/* All Tasks */}
            <button
                className={cn(
                    "w-full text-left px-3 py-2 rounded-md flex items-center",
                    activeTaskType === 'all' && !activeBoardId
                        ? "bg-blue-100 text-blue-800"
                        : "hover:bg-gray-100"
                )}
                onClick={() => handleSelection('all')}
            >
              <LayoutDashboard className="w-4 h-4 mr-2" />
              <span>All Tasks</span>
            </button>

            {/* Workloads Section */}
            <div>
              <button
                  className={cn(
                      "w-full text-left px-3 py-2 rounded-md flex items-center justify-between",
                      activeTaskType === 'Workload' && !activeBoardId
                          ? "bg-blue-100 text-blue-800"
                          : "hover:bg-gray-100"
                  )}
                  onClick={() => {
                    handleSelection('Workload');
                    toggleExpanded('workload');
                  }}
              >
                <div className="flex items-center">
                  <Users className="w-4 h-4 mr-2" />
                  <span>Workloads</span>
                </div>
                {expandedItems.has('workload') ? (
                    <ChevronDown className="w-4 h-4" />
                ) : (
                    <ChevronRight className="w-4 h-4" />
                )}
              </button>

              {expandedItems.has('workload') && boardsByType['Workload']?.length > 0 && (
                  <div className="ml-6 mt-1 space-y-1">
                    {boardsByType['Workload'].map(board => (
                        <button
                            key={board._id}
                            className={cn(
                                "w-full text-left px-3 py-1.5 rounded-md flex items-center text-sm",
                                activeBoardId === board._id
                                    ? "bg-blue-100 text-blue-800"
                                    : "hover:bg-gray-100"
                            )}
                            onClick={() => handleSelection('Workload', board._id)}
                        >
                          <span>{board.name}</span>
                        </button>
                    ))}
                  </div>
              )}
            </div>

            {/* Business Tasks */}
            <div>
              <button
                  className={cn(
                      "w-full text-left px-3 py-2 rounded-md flex items-center justify-between",
                      activeTaskType === 'Business' && !activeBoardId
                          ? "bg-blue-100 text-blue-800"
                          : "hover:bg-gray-100"
                  )}
                  onClick={() => {
                    handleSelection('Business');
                    toggleExpanded('business');
                  }}
              >
                <div className="flex items-center">
                  <Briefcase className="w-4 h-4 mr-2" />
                  <span>Business Tasks</span>
                </div>
                {boardsByType['Business']?.length > 0 && (
                    expandedItems.has('business') ? (
                        <ChevronDown className="w-4 h-4" />
                    ) : (
                        <ChevronRight className="w-4 h-4" />
                    )
                )}
              </button>

              {expandedItems.has('business') && boardsByType['Business']?.length > 0 && (
                  <div className="ml-6 mt-1 space-y-1">
                    {boardsByType['Business'].map(board => (
                        <button
                            key={board._id}
                            className={cn(
                                "w-full text-left px-3 py-1.5 rounded-md flex items-center text-sm",
                                activeBoardId === board._id
                                    ? "bg-blue-100 text-blue-800"
                                    : "hover:bg-gray-100"
                            )}
                            onClick={() => handleSelection('Business', board._id)}
                        >
                          <span>{board.name}</span>
                        </button>
                    ))}
                  </div>
              )}
            </div>

            {/* Enablement Tasks */}
            <div>
              <button
                  className={cn(
                      "w-full text-left px-3 py-2 rounded-md flex items-center justify-between",
                      activeTaskType === 'Enablement' && !activeBoardId
                          ? "bg-blue-100 text-blue-800"
                          : "hover:bg-gray-100"
                  )}
                  onClick={() => {
                    handleSelection('Enablement');
                    toggleExpanded('enablement');
                  }}
              >
                <div className="flex items-center">
                  <Lightbulb className="w-4 h-4 mr-2" />
                  <span>Enablement Tasks</span>
                </div>
                {boardsByType['Enablement']?.length > 0 && (
                    expandedItems.has('enablement') ? (
                        <ChevronDown className="w-4 h-4" />
                    ) : (
                        <ChevronRight className="w-4 h-4" />
                    )
                )}
              </button>

              {expandedItems.has('enablement') && boardsByType['Enablement']?.length > 0 && (
                  <div className="ml-6 mt-1 space-y-1">
                    {boardsByType['Enablement'].map(board => (
                        <button
                            key={board._id}
                            className={cn(
                                "w-full text-left px-3 py-1.5 rounded-md flex items-center text-sm",
                                activeBoardId === board._id
                                    ? "bg-blue-100 text-blue-800"
                                    : "hover:bg-gray-100"
                            )}
                            onClick={() => handleSelection('Enablement', board._id)}
                        >
                          <span>{board.name}</span>
                        </button>
                    ))}
                  </div>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6 overflow-hidden flex flex-col">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">{getPageTitle()}</h1>

            <div className="flex space-x-2">
              <div className="relative">
                <Input
                    placeholder="Search tasks..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-9 w-60"
                />
                <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
              </div>

              <Button
                  onClick={() => alert('Add task functionality to be implemented')}
                  className="bg-[#1f513f] text-white hover:bg-[#2a6f56]"
              >
                <Plus className="h-4 w-4 mr-2" />
                {activeTaskType === 'Workload' ? 'Add Workstream' : 'Add Task'}
              </Button>
            </div>
          </div>

          {/* Task Type Filter Indicator */}
          {activeTaskType === 'Workload' && !activeBoardId && (
              <div className="mb-4 flex items-center gap-2">
                <span className="text-sm text-gray-500">Showing:</span>
                <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
              All Tasks from Workload Boards
            </span>
              </div>
          )}

          {activeBoardId && (
              <div className="mb-4 flex items-center gap-2">
                <span className="text-sm text-gray-500">Showing:</span>
                <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
              All Task Types
            </span>
              </div>
          )}

          {/* Kanban Board */}
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex-1 overflow-x-auto">
              <div className="flex h-full space-x-4 pb-4">
                {columns.sort((a, b) => a.order - b.order).map((column) => (
                    <Column
                        key={column.id}
                        droppableId={column.id}
                        title={column.title}
                        tasks={tasks[column.id] || []}
                        boardId={activeBoardId}
                        allColumns={columns}
                        onColumnUpdate={(columnId, newTitle) => console.log(`Column ${columnId} updated to ${newTitle}`)}
                        onColumnDelete={(columnId) => console.log(`Column ${columnId} deleted`)}
                        onTaskUpdate={async (updatedTask) => {
                          try {
                            await taskApi.updateTask(updatedTask._id, updatedTask);
                          } catch (error) {
                            console.error('Error updating task:', error);
                          }
                        }}
                        onTaskDelete={async (taskId) => {
                          try {
                            await taskApi.deleteTask(taskId);
                          } catch (error) {
                            console.error('Error deleting task:', error);
                          }
                        }}
                    />
                ))}
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
  );
};

export default TasksPage;