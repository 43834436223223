import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { accountApi } from '../services/api';
import { Button } from '../components/ui/button';
import { useToast } from '../components/ui/use-toast';
import CreateAccountDialog from '../components/CreateAccountDialog';
import { Building2, Plus } from 'lucide-react';
import AccountCard from '../components/AccountCard';
import ProtectedRoute from '../components/auth/ProtectedRoute';
import { cn } from '../lib/utils';
import { colors } from '../lib/theme';

const Home = () => {
  const queryClient = useQueryClient();
  const [expandedAccounts, setExpandedAccounts] = useState(new Set());
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { toast } = useToast();
  const { 
    data: accounts = [], 
    isLoading, 
    error 
  } = useQuery('accounts', async () => {
    const data = await accountApi.getAccounts();
    return data || []; // Ensure we always return an array
  }, {
    onError: (error) => {
      console.error('Error fetching accounts:', error);
      toast({
        title: "Error",
        description: "Failed to load accounts",
        variant: "destructive"
      });
    }
  });

  // Update organizeAccounts to handle nested accounts recursively
  const organizeAccounts = (accounts) => {
    const accountMap = new Map(accounts.map(account => [account._id, { ...account, subAccounts: [] }]));
    const rootAccounts = [];

    accounts.forEach(account => {
      const mappedAccount = accountMap.get(account._id);
      if (account.parentAccount) {
        const parent = accountMap.get(account.parentAccount);
        if (parent) {
          parent.subAccounts.push(mappedAccount);
        }
      } else {
        rootAccounts.push(mappedAccount);
      }
    });

    return rootAccounts;
  };

  // Safely handle account creation success
  const handleAccountCreated = (newAccount) => {
    if (newAccount && newAccount._id) {
      queryClient.invalidateQueries('accounts');
      toast({
        title: "Success",
        description: "Account created successfully",
      });
    }
  };

  const toggleAccount = (accountId, event) => {
    event.preventDefault(); // Prevent navigation when clicking toggle
    setExpandedAccounts(current => {
      const newSet = new Set(current);
      if (newSet.has(accountId)) {
        newSet.delete(accountId);
      } else {
        newSet.add(accountId);
      }
      return newSet;
    });
  };

  if (isLoading) return (
    <div className="flex items-center justify-center min-h-[50vh]">
      <div className="text-lg">Loading accounts...</div>
    </div>
  );

  if (error) return (
    <div className="text-red-500">
      Error loading accounts: {error.message}
    </div>
  );

  const organizedAccounts = organizeAccounts(accounts);

  // Recursive component for rendering account hierarchy
  const AccountHierarchy = ({ account, level = 0 }) => {
    return (
      <div className="space-y-4">
        <AccountCard
          account={account}
          isExpanded={expandedAccounts.has(account._id)}
          onToggle={toggleAccount}
          isSubAccount={level > 0}
        />
        
        {account.subAccounts?.length > 0 && expandedAccounts.has(account._id) && (
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ml-6`}>
            {account.subAccounts.map(subAccount => (
              <div key={subAccount._id}>
                <AccountHierarchy account={subAccount} level={level + 1} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {/* Sticky header section */}
      <div className="sticky top-0 z-10 bg-white/95 backdrop-blur border-b border-gray-200 shadow-sm py-4 px-4 md:px-6 pt-10">
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center max-w-7xl mx-auto">
          <div>
            <h1 className="text-2xl md:text-3xl font-bold">Accounts</h1>
            <p className="text-gray-500 mt-1">Manage your customer accounts and their workspaces</p>
          </div>
          <ProtectedRoute requiredRoles="rbac_OrgAdmin">
            <Button 
              onClick={() => setCreateDialogOpen(true)}
              size="sm"
              className={cn(
                `bg-[${colors.brand.primary}]`,
                'text-white',
                `hover:bg-[${colors.background.secondary}]`,
                `hover:text-[${colors.brand.primary}]`,
                `border-[${colors.brand.primary}]`
              )}
            >
              <Plus className="w-4 h-4 mr-2" />
              New Account
            </Button>
          </ProtectedRoute>
        </div>
      </div>

      {/* Content area with padding */}
      <div className="px-4 md:px-6 pb-6">
        <CreateAccountDialog 
          open={createDialogOpen} 
          onOpenChange={setCreateDialogOpen} 
          onSuccess={handleAccountCreated} 
        />

        <div className="space-y-4">
          {organizedAccounts.map(account => (
            <AccountHierarchy key={account._id} account={account} />
          ))}
        </div>

        {accounts?.length === 0 && (
          <div className="text-center py-6 md:py-10">
            <Building2 className="w-12 h-12 mx-auto text-gray-400 mb-4" />
            <p className="text-gray-500">No accounts created yet.</p>
            <ProtectedRoute requiredRoles="rbac_OrgAdmin">
              <Button 
                onClick={() => setCreateDialogOpen(true)}
                className="mt-4"
              >
                Create your first account
              </Button>
            </ProtectedRoute>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;