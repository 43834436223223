import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { businessCaseApi } from '../../services/api';
import { useToast } from '../ui/use-toast';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Plus } from 'lucide-react';
import BusinessCaseHeader from './BusinessCaseHeader';
import BusinessCaseDetails from './BusinessCaseDetails';
import CustomFields from './CustomFields';
import { FieldEditor } from './fields';

const BusinessCaseSection = ({ boardId, isWorkload = false, onCloseSettings }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // State management
  const [showFieldEditor, setShowFieldEditor] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [pendingChanges, setPendingChanges] = useState({});
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);

  // Queries and Mutations
  const { data: businessCase, isLoading } = useQuery(
    ['business-case', boardId],
    () => businessCaseApi.getBusinessCase(boardId),
    {
      enabled: !!boardId && isWorkload,
    }
  );

  const updateMutation = useMutation(
    updates => businessCaseApi.updateBusinessCase(boardId, updates),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['business-case', boardId]);
        toast({
          title: 'Business case updated',
          description: 'Your changes have been saved successfully.',
        });
      },
      onError: error => {
        toast({
          title: 'Error',
          description: 'Failed to save changes. Please try again.',
          variant: 'destructive',
        });
        console.error('Error updating business case:', error);
      },
    }
  );

  const handleFieldChange = (key, value) => {
    setPendingChanges(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      await updateMutation.mutateAsync(pendingChanges);
      setPendingChanges({});
      toast({
        title: 'Changes saved',
        description: 'Your changes have been saved successfully.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to save changes. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleFieldDragEnd = async result => {
    if (!result.destination) return;

    const fields = [...(businessCase?.customFields || [])];
    const [removed] = fields.splice(result.source.index, 1);
    fields.splice(result.destination.index, 0, removed);

    const updatedFields = fields.map((field, index) => ({
      ...field,
      order: index,
    }));

    await updateMutation.mutateAsync({
      customFields: updatedFields,
    });
  };

  if (!isWorkload) return null;
  if (!boardId) return null;
  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="space-y-8">
      <BusinessCaseHeader onOpenSettings={() => setShowSettingsMenu(true)} />
      {/* Custom Fields Section - Moved to top */}
      <CustomFields
        businessCase={businessCase}
        showSettingsMenu={showSettingsMenu}
        onFieldChange={handleFieldChange}
        onFieldDragEnd={handleFieldDragEnd}
        onDeleteField={fieldKey =>
          updateMutation.mutate({
            customFields: businessCase.customFields.filter(f => f.key !== fieldKey),
          })
        }
      />

      {/* Overview Section */}
      <BusinessCaseDetails
        businessCase={{
          ...businessCase,
          boardId,
        }}
        onFieldChange={handleFieldChange}
      />

      {/* Save Changes Button */}
      <div className="flex justify-end">
        <Button
          onClick={handleSaveChanges}
          className="bg-[#1f513f] text-white hover:bg-[#f4f2ed] hover:text-[#1f513f]"
        >
          Save Changes
        </Button>
      </div>

      {/* Field Management Dialog */}
      <Dialog
        open={showSettingsMenu}
        onOpenChange={open => {
          setShowSettingsMenu(open);
          if (!open && onCloseSettings) {
            onCloseSettings();
          }
        }}
      >
        {' '}
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Manage Custom Fields</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <Button
              variant="outline"
              onClick={() => {
                setEditingField(null);
                setShowFieldEditor(true);
              }}
              className="w-full"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add New Field
            </Button>
            <div className="mt-4 space-y-2">
              {businessCase?.customFields?.map(field => (
                <div
                  key={field.key}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
                >
                  <div>
                    <p className="font-medium">{field.label}</p>
                    <p className="text-sm text-gray-500">Type: {field.type}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        setEditingField(field);
                        setShowFieldEditor(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        updateMutation.mutate({
                          customFields: businessCase.customFields.filter(f => f.key !== field.key),
                        })
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Field Editor Dialog */}
      <FieldEditor
        isOpen={showFieldEditor}
        onClose={() => {
          setShowFieldEditor(false);
          setEditingField(null);
        }}
        onSave={async fieldData => {
          if (editingField) {
            const updatedFields = businessCase.customFields.map(f =>
              f.key === editingField.key ? { ...f, ...fieldData } : f
            );
            await updateMutation.mutateAsync({ customFields: updatedFields });
          } else {
            const newField = {
              ...fieldData,
              order: businessCase.customFields?.length || 0,
            };
            await updateMutation.mutateAsync({
              customFields: [...(businessCase.customFields || []), newField],
            });
          }
          setShowFieldEditor(false);
          setEditingField(null);
        }}
        initialData={editingField}
      />
    </div>
  );
};

export default BusinessCaseSection;
