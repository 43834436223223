import React, { useState, useRef, useEffect } from 'react';
import { Send, Bot, User, Loader2, Trash2, Clock } from 'lucide-react';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { ScrollArea } from '../components/ui/scroll-area';
import { Avatar, AvatarFallback } from '../components/ui/avatar';
import { useToast } from '../components/ui/use-toast';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { aiApi } from '../services/api';
import { logDebug } from '../utils/logger';

const AIChat = ({ boardId, accountId, onTaskUpdate }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef(null);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Debug props on mount and update
  useEffect(() => {
    logDebug('[AIChat] Initialized with props:', {
      boardId,
      accountId,
      hasTaskUpdateCallback: !!onTaskUpdate
    });
  }, [boardId, accountId, onTaskUpdate]);

  // Chat history query using AI API
  useQuery(
    ['chat-history', accountId],
    () => aiApi.getChatHistory(accountId),
    {
      enabled: !!accountId,
      onSuccess: (data) => {
        logDebug('[AIChat] Successfully fetched history:', {
          messageCount: data.length
        });
        const historyMessages = data.map(h => ({
          role: 'assistant',
          content: h.response,
          timestamp: new Date(h.timestamp)
        }));
        setMessages(historyMessages);
      },
      onError: (error) => {
        console.error('[AIChat] Error fetching history:', error);
      }
    }
  );

  // Clear history mutation using AI API
  const clearHistoryMutation = useMutation(
    () => aiApi.clearHistory(accountId),
    {
      onSuccess: () => {
        setMessages([]);
        toast({
          title: 'History Cleared',
          description: 'Chat history has been cleared successfully.'
        });
      },
      onError: (error) => {
        console.error('[AIChat] Error clearing history:', error);
        toast({
          title: 'Error',
          description: 'Failed to clear chat history: ' + error.message,
          variant: 'destructive'
        });
      }
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    logDebug('[AIChat] Submitting new message:', {
      inputLength: input.length,
      boardId,
      accountId
    });

    const userMessage = {
      role: 'user',
      content: input,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const data = await aiApi.sendMessage(input, { boardId, accountId });
      
      logDebug('[AIChat] Received AI response:', {
        hasContent: !!data.response,
        actionCount: data.actions?.length,
        actions: data.actions?.map(a => a.type)
      });
      
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: data.response,
        timestamp: new Date(),
        actions: data.actions
      }]);

      // Handle actions
      if (data.actions?.length > 0) {
        logDebug('[AIChat] Processing actions:', {
          count: data.actions.length,
          types: data.actions.map(a => a.type)
        });

        const uniqueTypes = new Set(data.actions.map(a => a.type));
        
        if (uniqueTypes.has('CREATE_TASK') || uniqueTypes.has('UPDATE_TASK')) {
          await queryClient.invalidateQueries(['tasks', boardId]);
        }
        if (uniqueTypes.has('CREATE_BOARD') || uniqueTypes.has('UPDATE_BOARD')) {
          await queryClient.invalidateQueries(['boards', accountId]);
        }
        
        onTaskUpdate?.();
        
        toast({
          title: "Updates Applied",
          description: `${data.actions.length} action${data.actions.length === 1 ? '' : 's'} performed successfully.`,
          variant: "success",
        });
      }
    } catch (error) {
      console.error('[AIChat] Error in message submission:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: 'Sorry, I encountered an error processing your request.',
        timestamp: new Date(),
        error: true
      }]);
      
      toast({
        title: "Error",
        description: "Failed to process AI request. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full h-full flex flex-col">
      <div className="flex justify-between items-center p-4 border-b">
        <h3 className="font-semibold">AI Assistant</h3>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm">
              <Clock className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem 
              onClick={() => clearHistoryMutation.mutate()}
              className="text-red-600"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Clear History
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      
      <ScrollArea ref={scrollRef} className="flex-1 p-4 space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex items-start gap-3 ${
              message.role === 'user' ? 'flex-row-reverse' : ''
            }`}
          >
            <Avatar className={`${message.role === 'user' ? 'bg-blue-100' : 'bg-green-100'}`}>
              <AvatarFallback>
                {message.role === 'user' ? <User className="h-4 w-4" /> : <Bot className="h-4 w-4" />}
              </AvatarFallback>
            </Avatar>
            <div
              className={`rounded-lg px-3 py-2 max-w-[80%] ${
                message.role === 'user'
                  ? 'bg-blue-100 text-blue-900'
                  : message.error
                  ? 'bg-red-100 text-red-900'
                  : 'bg-gray-100 text-gray-900'
              }`}
            >
              <p className="text-sm whitespace-pre-wrap">{message.content}</p>
              {message.actions && message.actions.length > 0 && (
                <div className="mt-2 pt-2 border-t border-gray-200 text-xs text-gray-600">
                  <p className="font-medium mb-1">Actions performed:</p>
                  {message.actions.map((action, i) => (
                    <div key={i} className="flex items-center gap-1">
                      <span>•</span>
                      <span>{action.type}: {action.result?.message || 'Completed'}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex items-center gap-2 text-gray-500">
            <Loader2 className="h-4 w-4 animate-spin" />
            <span className="text-sm">Thinking...</span>
          </div>
        )}
      </ScrollArea>
      
      <CardContent className="border-t p-4">
        <form onSubmit={handleSubmit} className="flex gap-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask me to help with your tasks..."
            className="flex-1 px-3 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isLoading}
          />
          <Button type="submit" size="sm" disabled={isLoading}>
            <Send className="h-4 w-4" />
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default AIChat;