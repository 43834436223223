import React from 'react';
import { Button } from '../ui/button';
import { Settings } from 'lucide-react';

const BusinessCaseHeader = ({ onOpenSettings }) => {
  return (
    <div className="flex items-center justify-between border-b border-gray-200 pb-4">
      <div>
        <h2 className="text-xl font-semibold text-gray-900">Business Case</h2>
        <p className="text-sm text-gray-500 mt-1">
          Define and track the business context for this workload
        </p>
      </div>
      <div className="flex items-center gap-2">
        <Button 
          variant="outline"
          size="sm"
          onClick={onOpenSettings}
        >
          <Settings className="w-4 h-4 mr-2" />
          Customize Fields
        </Button>
      </div>
    </div>
  );
};

export default BusinessCaseHeader;