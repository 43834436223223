import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Building2, Users, ChevronDown, ChevronRight } from 'lucide-react';
import { Badge } from './ui/badge';
import { cn } from '../lib/utils';

const AccountCard = ({ account, isExpanded, onToggle, isSubAccount, level }) => {
  const handleAccountSelect = () => {
    localStorage.setItem('currentAccountId', account._id);
  };

  return (
    <div className="flex items-start gap-2">
      {account.subAccounts?.length > 0 && (
        <button
          onClick={(e) => onToggle(account._id, e)}
          className="mt-4 p-1 hover:bg-gray-100 rounded"
        >
          {isExpanded ? (
            <ChevronDown className="w-5 h-5" />
          ) : (
            <ChevronRight className="w-5 h-5" />
          )}
        </button>
      )}
      <Link 
        to={`/accounts/${account._id}`} 
        className="flex-1"
        onClick={handleAccountSelect}
      >
        <Card 
          className={cn(
            "shadow-sm hover:shadow-md transition-all duration-200 bg-white border-0",
            isSubAccount ? 'bg-white/50' : 'bg-white',
            "hover:bg-white"
          )}
        >
          <CardHeader>
            <div className="flex items-center space-x-3">
              <div className={cn(
                "w-10 h-10 rounded-full flex items-center justify-center",
                isSubAccount ? 'bg-gray-100' : 'bg-primary/10'
              )}>
                <Building2 className={cn(
                  "w-5 h-5",
                  isSubAccount ? 'text-gray-600' : 'text-primary'
                )} />
              </div>
              <div>
                <CardTitle>{account.name}</CardTitle>
                {isSubAccount && (
                  <Badge variant="secondary" className="mt-1">
                    Sub Account
                  </Badge>
                )}
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-4">
              <div className="flex items-center text-sm text-gray-500">
                <Users className="w-4 h-4 mr-2" />
                <span>{account.teamCount || 0} team members</span>
              </div>
              {account.subAccounts?.length > 0 && (
                <div className="flex items-center text-sm">
                  <Badge variant="outline" className="bg-primary/5">
                    {account.subAccounts.length} subaccount{account.subAccounts.length !== 1 ? 's' : ''}
                  </Badge>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};

export default AccountCard;