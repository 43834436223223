import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Card } from '../components/ui/card';
import { Alert, AlertDescription } from '../components/ui/alert';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Sector } from 'recharts';
import { metricsApi } from '../services/metricsApi';
import { AlertCircle } from 'lucide-react';

// Using the same brand colors from WorkstreamMetrics with transparency options
const COLORS = {
  cost: {
    realized: 'hsl(217 48% 48%)', // Brand secondary (Alpine Blue)
    potential: 'hsla(217 48% 92%, 0.6)', // Very light version of secondary with transparency
  },
};

// Additional colors for different cost reduction types
const COST_TYPE_COLORS = [
  'hsl(210 60% 34%)', // accent.blue[500] - Alpine Lake
  'hsl(16 47% 49%)', // accent.rust[500] - Desert Rock
  'hsl(39 50% 48%)', // accent.gold[500] - Autumn Leaf
  'hsl(210 45% 64%)', // accent.blue[200] - Sky
];

// Transparent versions
const TRANSPARENT_COST_TYPE_COLORS = COST_TYPE_COLORS.map(color => {
  const match = color.match(/hsl\((\d+)\s+(\d+)%\s+(\d+)%\)/);
  if (match) {
    const [, h, s, l] = match;
    return `hsla(${h}, ${s}%, ${l}%, 0.3)`;
  }
  return `${color.replace(')', ', 0.3)')}`;
});

const RADIAN = Math.PI / 180;

// Enhanced active shape renderer for Tableau-like effect
const renderActiveShape = props => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5} // Make active sector larger
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        stroke={fill}
        strokeWidth={2}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 8}
        outerRadius={outerRadius + 12}
        fill={fill}
      />
    </g>
  );
};

const CostReductionMetrics = ({ accountId }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onPieLeave = useCallback(() => {
    setActiveIndex(null);
  }, [setActiveIndex]);

  const {
    data: metrics,
    isLoading,
    error,
  } = useQuery(
    ['workstream-cost-metrics', accountId],
    () => metricsApi.getWorkstreamMetrics(accountId, 'cost'),
    {
      enabled: Boolean(accountId),
    }
  );

  if (isLoading) {
    return (
      <Card className="p-4">
        <div className="h-64 flex items-center justify-center">
          <p className="text-gray-500">Loading metrics...</p>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>Error loading metrics: {error.message}</AlertDescription>
      </Alert>
    );
  }

  if (!metrics?.length) {
    return (
      <Card className="p-4">
        <div className="h-64 flex items-center justify-center">
          <p className="text-gray-500">No cost reduction metrics available</p>
        </div>
      </Card>
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-3 border rounded shadow">
          <p className="font-medium">{data.workloadName}</p>
          <div className="space-y-1 mt-2">
            <p className="text-sm">
              <span className="font-medium">Value: </span>${(data.value / 1000000).toFixed(1)}M
            </p>
            <p className="text-sm">
              <span className="font-medium">Status: </span>
              {data.isRealized ? 'Realized' : 'Potential'}
            </p>
            {data.costDriver && (
              <p className="text-sm">
                <span className="font-medium">Driver: </span>
                {data.costDriver.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </p>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
    workloadName,
  }) => {
    const radius = outerRadius * 1.2;
    const x1 = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
    const y1 = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
    const x2 = cx + radius * Math.cos(-midAngle * RADIAN);
    const y2 = cy + radius * Math.sin(-midAngle * RADIAN);
    const textAnchor = x2 > cx ? 'start' : 'end';
    const formattedValue = `$${(value / 1000000).toFixed(1)}M`;

    return (
      <g>
        <path
          d={`M${x1},${y1}L${x2},${y2}`}
          stroke={COLORS.cost.realized}
          strokeWidth={1}
          fill="none"
        />
        <text
          x={x2 + (x2 > cx ? 5 : -5)}
          y={y2}
          textAnchor={textAnchor}
          dominantBaseline="central"
          className="text-xs fill-current font-medium"
        >
          {formattedValue}
        </text>
      </g>
    );
  };

  return (
    <div className="h-64">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={metrics}
            dataKey="value"
            nameKey="workloadName"
            cx="50%"
            cy="50%"
            outerRadius={100}
            paddingAngle={0}
            label={renderCustomizedLabel}
            labelLine={true}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {metrics.map((entry, index) => {
              // Determine color based on cost driver type and realized status
              let fillColor;
              let strokeColor;

              if (entry.isRealized) {
                // For realized cost savings, use full opacity
                fillColor =
                  index === 0
                    ? COLORS.cost.realized
                    : COST_TYPE_COLORS[(index - 1) % COST_TYPE_COLORS.length];
                strokeColor = fillColor;
              } else {
                // For potential cost savings, use transparent version
                fillColor =
                  index === 0
                    ? COLORS.cost.potential
                    : TRANSPARENT_COST_TYPE_COLORS[
                        (index - 1) % TRANSPARENT_COST_TYPE_COLORS.length
                      ];
                strokeColor = '#e11d48'; // Red outline for potential
              }

              return (
                <Cell
                  key={`cell-${index}`}
                  fill={fillColor}
                  stroke={strokeColor}
                  strokeWidth={entry.isRealized ? 1 : 1.5}
                />
              );
            })}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CostReductionMetrics;
