import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Plus, FileCode, Grid, GitBranch } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Tabs, TabsList, TabsTrigger } from '../components/ui/tabs';
import { templateApi } from '../services/api';
import InteractiveMaturityFlow from '../components/Template/InteractiveMaturityFlow';
import { cn } from '../lib/utils';
import { colors } from '../lib/theme';

const TemplatesPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [viewMode, setViewMode] = useState('grid');

  const { data: templates, isLoading } = useQuery('templates', () => templateApi.getAllTemplates());

  const handleTemplateClick = templateId => {
    navigate(`/templates/edit/${templateId}`);
  };

  const handleMaturityFlowSave = async updatedTemplates => {
    try {
      await Promise.all(
        updatedTemplates.map(template => templateApi.updateTemplate(template._id, template))
      );
      queryClient.invalidateQueries('templates');
    } catch (error) {
      console.error('Error saving template updates:', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* Sticky header section */}
      <div className="sticky top-0 z-10 bg-white/95 backdrop-blur border-b border-gray-200 shadow-sm py-4 px-4 md:px-6 pt-10">
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-between sm:items-center max-w-7xl mx-auto">
          <div>
            <h1 className="text-2xl md:text-3xl font-bold">Templates</h1>
            <p className="text-gray-500 mt-1">Manage your board templates</p>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Tabs value={viewMode} onValueChange={setViewMode} className="hidden md:flex">
              <TabsList>
                <TabsTrigger value="grid">
                  <Grid className="w-4 h-4 mr-2" />
                  Grid View
                </TabsTrigger>
                <TabsTrigger value="flow">
                  <GitBranch className="w-4 h-4 mr-2" />
                  Maturity Flow
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <Button
              onClick={() => navigate('/templates/new')}
              size="sm"
              className={cn(
                "w-full sm:w-auto",
                `bg-[${colors.brand.primary}]`,
                'text-white',
                `hover:bg-[${colors.background.secondary}]`,
                `hover:text-[${colors.brand.primary}]`,
                `border-[${colors.brand.primary}]`
              )}
            >
              <Plus className="w-4 h-4 mr-2" />
              Create Template
            </Button>
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className="px-4 md:px-6 pb-6">
        {isLoading ? (
          <div className="text-center py-10">Loading templates...</div>
        ) : templates?.length === 0 ? (
          <div className="text-center py-10">
            <FileCode className="w-12 h-12 mx-auto text-gray-400 mb-4" />
            <p className="text-gray-500">No templates created yet.</p>
            <Button 
              className={cn(
                "mt-4",
                `bg-[${colors.brand.primary}]`,
                'text-white',
                `hover:bg-[${colors.background.secondary}]`,
                `hover:text-[${colors.brand.primary}]`,
                `border-[${colors.brand.primary}]`
              )}
              onClick={() => navigate('/templates/new')}
            >
              Create your first template
            </Button>
          </div>
        ) : viewMode === 'grid' ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {templates.map(template => (
              <Card
                key={template._id}
                className="hover:shadow-md transition-shadow cursor-pointer"
                onClick={() => handleTemplateClick(template._id)}
              >
                <CardHeader>
                  <CardTitle>{template.name}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-sm text-gray-500">{template.type} Template</p>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <InteractiveMaturityFlow templates={templates} onSave={handleMaturityFlowSave} />
        )}
      </div>
    </div>
  );
};

export default TemplatesPage;
