// src/components/auth/ProtectedRoute.js
import useCheckAuth from '../../hooks/useCheckAuth';

const ProtectedRoute = ({ children, requiredRoles }) => {
  // Convert single role to array for backwards compatibility and ensure it's not undefined
  const rolesToCheck = requiredRoles 
    ? (Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles])
    : [];
  
  // Add BelayAdmin as an alternative role
  const allRoles = [...rolesToCheck, 'BelayAdmin'];
  const { isLoading, hasAccess } = useCheckAuth(allRoles);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!hasAccess) {
    return null;
  }

  return children;
};

export default ProtectedRoute;