import React, { useState } from 'react';
import { format, format as formatDate } from 'date-fns';
import { Pencil, Trash, Plus, Calendar, AlertCircle } from 'lucide-react'; // Import icons
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { boardApi } from '../../services/api';
import { useToast } from '../ui/use-toast';
import { Badge } from '../ui/badge';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Input } from '../ui/input';
import { Label } from '../ui/label';

const getStatusColor = status => {
  const colors = {
    Not_Started: 'bg-gray-100 text-gray-800',
    In_Progress: 'bg-blue-100 text-blue-800',
    Completed: 'bg-green-100 text-green-800',
    Blocked: 'bg-red-100 text-red-800',
  };
  return colors[status] || colors['Not_Started'];
};

const MilestoneDialog = ({ open, onOpenChange, onSave, milestone }) => {
  const [formData, setFormData] = useState({
    name: '',
    customName: '',
    targetDate: '',
    notes: '',
  });

  // Populate formData with milestone data when editing
  React.useEffect(() => {
    if (milestone) {
      setFormData({
        name: milestone.name || '',
        customName: milestone.customName || '',
        targetDate: milestone.targetDate
          ? formatDate(new Date(milestone.targetDate), 'yyyy-MM-dd') // Format date to yyyy-MM-dd
          : '',
        notes: milestone.notes || '',
      });
    } else {
      setFormData({
        name: '',
        customName: '',
        targetDate: '',
        notes: '',
      });
    }
  }, [milestone, open]);

  const handleSubmit = () => {
    onSave({ ...milestone, ...formData }); // Merge milestone data with formData
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>{milestone ? 'Edit' : 'Add'} Milestone</DialogTitle>
          <DialogDescription>Configure milestone details and target dates</DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label>Milestone Type</Label>
            <Select
              value={formData.name}
              onValueChange={value =>
                setFormData(prev => ({
                  ...prev,
                  name: value,
                }))
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select milestone type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Development">Development</SelectItem>
                <SelectItem value="QA">QA</SelectItem>
                <SelectItem value="Soft_Production">Soft Production</SelectItem>
                <SelectItem value="Production">Production</SelectItem>
                <SelectItem value="Custom">Custom</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {formData.name === 'Custom' && (
            <div className="grid gap-2">
              <Label>Custom Name</Label>
              <Input
                value={formData.customName}
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    customName: e.target.value,
                  }))
                }
                placeholder="Enter custom milestone name"
              />
            </div>
          )}

          <div className="grid gap-2">
            <Label>Target Date</Label>
            <Input
              type="date"
              value={formData.targetDate}
              onChange={e =>
                setFormData(prev => ({
                  ...prev,
                  targetDate: e.target.value,
                }))
              }
            />
          </div>

          <div className="grid gap-2">
            <Label>Notes</Label>
            <Input
              value={formData.notes}
              onChange={e =>
                setFormData(prev => ({
                  ...prev,
                  notes: e.target.value,
                }))
              }
              placeholder="Add any relevant notes"
            />
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!formData.name}>
            {milestone ? 'Update' : 'Add'} Milestone
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const MilestoneCard = ({ milestone, onStatusChange, onEdit, onDelete }) => {
  const displayName =
    milestone.name === 'Custom' ? milestone.customName : milestone.name.replace(/_/g, ' ');

  return (
    <Card className="mb-4">
      <CardHeader className="pb-2">
        <div className="flex justify-between items-start">
          <CardTitle className="text-lg">{displayName}</CardTitle>
          <div className="flex items-center space-x-2">
            <Badge className={getStatusColor(milestone.status)}>
              {milestone.status.replace(/_/g, ' ')}
            </Badge>
            <Button
              variant="ghost"
              size="sm"
              className="p-1 text-gray-700 hover:text-blue-500"
              onClick={onEdit}
            >
              <Pencil className="w-4 h-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="p-1 text-gray-700 hover:text-red-500"
              onClick={onDelete}
            >
              <Trash className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {milestone.targetDate && (
            <div className="flex items-center text-sm text-gray-500">
              <Calendar className="w-4 h-4 mr-2" />
              Target: {format(new Date(milestone.targetDate), 'MMM d, yyyy')}
            </div>
          )}

          {milestone.notes && (
            <div className="flex items-center text-sm text-gray-500">
              <AlertCircle className="w-4 h-4 mr-2" />
              {milestone.notes}
            </div>
          )}

          <div className="pt-2">
            <Select
              value={milestone.status}
              onValueChange={value => onStatusChange(milestone._id, value)}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Update status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Not_Started">Not Started</SelectItem>
                <SelectItem value="In_Progress">In Progress</SelectItem>
                <SelectItem value="Completed">Completed</SelectItem>
                <SelectItem value="Blocked">Blocked</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const MilestoneTracking = ({ board, onUpdate }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [editingMilestone, setEditingMilestone] = useState(null);
  const { toast } = useToast();

  const handleAddMilestone = async milestoneData => {
    try {
      const updatedBoard = await boardApi.addMilestone(board._id, {
        ...milestoneData,
        status: 'Not_Started',
        order: (board.milestones?.length || 0) * 1000 + 1000,
      });

      onUpdate(updatedBoard);
      toast({
        title: 'Milestone added',
        description: 'New milestone has been created successfully.',
      });
    } catch (error) {
      console.error('Error adding milestone:', error);
      toast({
        title: 'Error',
        description: 'Failed to add milestone',
        variant: 'destructive',
      });
    }
  };

  const handleEditMilestone = async milestoneData => {
    try {
      const updatedBoard = await boardApi.updateMilestone(
        board._id,
        milestoneData._id, // Ensure the milestone ID is passed correctly
        {
          name: milestoneData.name,
          customName: milestoneData.customName,
          targetDate: milestoneData.targetDate,
          notes: milestoneData.notes,
          status: milestoneData.status, // Include status if needed
        }
      );

      onUpdate(updatedBoard);
      toast({
        title: 'Milestone updated',
        description: 'Milestone has been updated successfully.',
      });
    } catch (error) {
      console.error('Error updating milestone:', error);
      toast({
        title: 'Error',
        description: 'Failed to update milestone',
        variant: 'destructive',
      });
    }
  };

  const handleDeleteMilestone = async milestoneId => {
    try {
      await boardApi.deleteMilestone(board._id, milestoneId); // Call API to delete milestone
      const updatedBoard = await boardApi.getBoardById(board._id); // Fetch updated board
      onUpdate(updatedBoard); // Update the board state
      toast({
        title: 'Milestone deleted',
        description: 'Milestone has been removed successfully.',
      });
    } catch (error) {
      console.error('Error deleting milestone:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete milestone',
        variant: 'destructive',
      });
    }
  };

  const handleEditClick = milestone => {
    setEditingMilestone({
      ...milestone,
      targetDate: milestone.targetDate || '', // Ensure targetDate is passed
      notes: milestone.notes || '', // Ensure notes are passed
    });
    setShowDialog(true);
  };

  const handleMilestoneSave = milestoneData => {
    if (editingMilestone) {
      handleEditMilestone({ ...editingMilestone, ...milestoneData });
    } else {
      handleAddMilestone(milestoneData);
    }
  };

  const handleStatusChange = async (milestoneId, status) => {
    try {
      console.log('Updating milestone with ID:', milestoneId);
      const updatedBoard = await boardApi.updateMilestoneStatus(board._id, milestoneId, status);
      onUpdate(updatedBoard);
      toast({
        title: 'Status updated',
        description: 'Milestone status has been updated successfully.',
      });
    } catch (error) {
      console.error('Error updating milestone status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update milestone status',
        variant: 'destructive',
      });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold">Milestones</h3>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            setEditingMilestone(null);
            setShowDialog(true);
          }}
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Milestone
        </Button>
      </div>

      <div className="space-y-4">
        {board.milestones?.map(milestone => (
          <MilestoneCard
            key={milestone._id}
            milestone={milestone}
            onStatusChange={handleStatusChange}
            onEdit={() => handleEditClick(milestone)} // Use the updated handleEditClick
            onDelete={() => handleDeleteMilestone(milestone._id)} // Pass delete handler
          />
        ))}

        {(!board.milestones || board.milestones.length === 0) && (
          <div className="text-center py-8 text-gray-500">No milestones configured</div>
        )}
      </div>

      <MilestoneDialog
        open={showDialog}
        onOpenChange={setShowDialog}
        onSave={handleMilestoneSave}
        milestone={editingMilestone}
      />
    </div>
  );
};

export default MilestoneTracking;
