import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TrendingUp, TrendingDown, Shield, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';
import { Badge } from '../ui/badge';
import { boardApi } from '../../services/api'; // Import the boardApi

const COLORS = ['#1f513f', '#2a6f56', '#358d6d', '#40ab84', '#4bc99b'];

const MetricsDrillDownDialog = ({
  isOpen,
  onClose,
  metrics,
  initialTab = 'revenue',
  title = 'Business Value Analysis',
  defaultAccountId, // We need accountId for the workstream links
}) => {
  // Set a default value for accountId from localStorage if not provided
  const accountId = defaultAccountId || localStorage.getItem('currentAccountId');
  const [activeTab, setActiveTab] = React.useState(initialTab);
  const [boardNames, setBoardNames] = useState({});

  useEffect(() => {
    setActiveTab(initialTab); // Set the active tab based on the initialTab prop
  }, [initialTab]);

  useEffect(() => {
    const fetchBoardNames = async () => {
      const boardIds = metrics.map(metric => metric.boardId).filter(Boolean);
      const uniqueBoardIds = [...new Set(boardIds)];

      const boardNamesMap = {};
      for (const boardId of uniqueBoardIds) {
        try {
          const board = await boardApi.getBoardById(boardId);
          boardNamesMap[boardId] = board.name;
        } catch (error) {
          console.error(`Failed to fetch board name for ID ${boardId}:`, error);
        }
      }
      setBoardNames(boardNamesMap);
    };

    fetchBoardNames();
  }, [metrics]);

  // Calculate metrics totals
  const metricsData = React.useMemo(
    () => ({
      revenue: {
        total: metrics.reduce((sum, w) => {
          const valueMap = {
            '100k': 100000,
            '500k': 500000,
            '1m': 1000000,
            '5m': 5000000,
            '5m_plus': 7500000,
          };
          return sum + (valueMap[w.revenueImpact] || 0);
        }, 0),
        opportunities: metrics.filter(w => w.revenueImpact).length,
      },
      cost: {
        total: metrics.reduce((sum, w) => {
          const valueMap = {
            '50k': 50000,
            '200k': 200000,
            '500k': 500000,
            '1m': 1000000,
            '1m_plus': 1500000,
          };
          return sum + (valueMap[w.costSavings] || 0);
        }, 0),
        opportunities: metrics.filter(w => w.costSavings).length,
      },
      risk: {
        critical: metrics.filter(w => w.riskSeverity === 'critical').length,
        high: metrics.filter(w => w.riskSeverity === 'high').length,
        medium: metrics.filter(w => w.riskSeverity === 'medium').length,
        low: metrics.filter(w => w.riskSeverity === 'low').length,
      },
    }),
    [metrics]
  );

  // Format revenue value for display
  const formatRevenue = value => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    }
    return `$${(value / 1000).toFixed(0)}k`;
  };

  // Map revenue impact code to formatted display value
  const getRevenueValue = revenueImpact => {
    const valueMap = {
      '100k': 100000,
      '500k': 500000,
      '1m': 1000000,
      '5m': 5000000,
      '5m_plus': 7500000,
    };
    return valueMap[revenueImpact] || 0;
  };

  // Map revenue driver codes to display text
  const getRevenueDriverDisplay = driver => {
    const driverMap = {
      new_market: 'New Market Entry',
      acquisition: 'Customer Acquisition',
      cross_sell: 'Cross-sell/Upsell',
      product_launch: 'Product Launch',
      retention: 'Customer Retention',
    };
    return driverMap[driver] || driver;
  };

  const renderRevenueContent = () => {
    // Format revenue drivers data for pie chart
    // Handle both aggregated and direct metrics
    const revenueData = Object.entries(
      metrics.reduce((acc, metric) => {
        // Check if this is an aggregated metric or direct metric
        const isAggregatedMetric =
          metric.hasOwnProperty('workloadName') && metric.hasOwnProperty('value');

        if (isAggregatedMetric) {
          // For aggregated metrics, we don't have driver info, so use "Other" as category
          if (metric.type === 'revenue') {
            const label = 'Other';
            acc[label] = (acc[label] || 0) + 1;
          }
        } else if (metric.revenueDriver) {
          // For direct metrics with driver info
          const label = getRevenueDriverDisplay(metric.revenueDriver);
          acc[label] = (acc[label] || 0) + 1;
        }
        return acc;
      }, {})
    ).map(([name, value]) => ({ name, value }));

    // Based on backend response structure:
    // - Backend aggregation returns different structure than the raw metrics
    // - For direct metrics: we use revenueImpact, revenueDriver properties
    // - For aggregated metrics: we use value, isRealized, workloadName, _id instead

    const revenueTableData = metrics
      .map(metric => {
        // Check if this is an aggregated metric or direct metric
        const isAggregatedMetric =
          metric.hasOwnProperty('workloadName') && metric.hasOwnProperty('value');

        if (isAggregatedMetric) {
          // Handle aggregated metric
          return {
            id: metric._id || metric.boardId, // Try both formats
            name: metric.workloadName || 'Unnamed Workstream',
            status: metric.isRealized ? 'Realized' : 'Potential',
            value: metric.value,
            driver: 'N/A',
            type: metric.type,
          };
        } else {
          // Handle direct metric (original format)
          if (!metric.revenueImpact) return null;

          // Improved name extraction
          let workstreamName = 'Unnamed Workstream';
          if (boardNames[metric.boardId]) {
            workstreamName = boardNames[metric.boardId];
          } else if (metric.boardName) {
            workstreamName = metric.boardName;
          } else if (metric.workloadName) {
            workstreamName = metric.workloadName;
          } else if (metric.board && Array.isArray(metric.board) && metric.board[0]?.name) {
            workstreamName = metric.board[0].name;
          }

          return {
            id: metric.boardId || metric._id,
            name: workstreamName,
            status: metric.status || 'Potential',
            value: getRevenueValue(metric.revenueImpact),
            driver: getRevenueDriverDisplay(metric.revenueDriver),
            type: 'revenue',
          };
        }
      })
      .filter(Boolean) // Remove null entries
      .filter(item => item.type === 'revenue') // Only include revenue metrics
      .sort((a, b) => b.value - a.value); // Sort by value descending

    return (
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <TrendingUp className="w-5 h-5 text-emerald-600" />
                <div>
                  <div className="text-2xl font-bold">
                    ${(metricsData.revenue.total / 1000000).toFixed(1)}M
                  </div>
                  <div className="text-sm text-gray-500">Total Revenue Potential</div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <div className="text-2xl font-bold">{metricsData.revenue.opportunities}</div>
                <div className="text-sm text-gray-500">Revenue Opportunities</div>
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Revenue Drivers Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={revenueData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label
                  >
                    {revenueData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Revenue Opportunities by Workstream</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Workstream</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Category</TableHead>
                  <TableHead className="text-right">Revenue Opportunity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {revenueTableData.map(row => (
                  <TableRow key={row.id}>
                    <TableCell className="font-medium">
                      {accountId ? (
                        <Link
                          to={`/accounts/${accountId}/workload/default?board=${row.id}`}
                          className="flex items-center hover:underline text-blue-600"
                        >
                          {row.name}
                          <ExternalLink className="ml-1 h-3 w-3" />
                        </Link>
                      ) : (
                        <span>{row.name}</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Badge variant={row.status === 'Realized' ? 'success' : 'secondary'}>
                        {row.status}
                      </Badge>
                    </TableCell>
                    <TableCell>{row.driver === 'N/A' ? 'Not Available' : row.driver}</TableCell>
                    <TableCell className="text-right font-bold">
                      {formatRevenue(row.value)}
                    </TableCell>
                  </TableRow>
                ))}
                {revenueTableData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} className="text-center py-4 text-gray-500">
                      No revenue opportunities found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderCostContent = () => {
    const costData = Object.entries(
      metrics.reduce((acc, metric) => {
        const isAggregatedMetric =
          metric.hasOwnProperty('workloadName') && metric.hasOwnProperty('value');

        if (isAggregatedMetric) {
          if (metric.type === 'cost') {
            const label = 'Other';
            acc[label] = (acc[label] || 0) + 1;
          }
        } else if (metric.costDriver) {
          const label = metric.costDriver;
          acc[label] = (acc[label] || 0) + 1;
        }
        return acc;
      }, {})
    ).map(([name, value]) => ({ name, value }));

    const costTableData = metrics
      .map(metric => {
        const isAggregatedMetric =
          metric.hasOwnProperty('workloadName') && metric.hasOwnProperty('value');

        if (isAggregatedMetric) {
          return {
            id: metric._id || metric.boardId,
            name: metric.workloadName || 'Unnamed Workstream',
            status: metric.isRealized ? 'Realized' : 'Potential',
            value: metric.value,
            driver: 'N/A',
            type: metric.type,
          };
        } else {
          if (!metric.costSavings) return null;

          let workstreamName = 'Unnamed Workstream';
          if (boardNames[metric.boardId]) {
            workstreamName = boardNames[metric.boardId];
          } else if (metric.boardName) {
            workstreamName = metric.boardName;
          } else if (metric.workloadName) {
            workstreamName = metric.workloadName;
          } else if (metric.board && Array.isArray(metric.board) && metric.board[0]?.name) {
            workstreamName = metric.board[0].name;
          }

          return {
            id: metric.boardId || metric._id,
            name: workstreamName,
            status: metric.status || 'Potential',
            value: metric.costSavings,
            driver: metric.costDriver || 'N/A',
            type: 'cost',
          };
        }
      })
      .filter(Boolean)
      .filter(item => item.type === 'cost')
      .sort((a, b) => b.value - a.value);

    return (
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <TrendingDown className="w-5 h-5 text-red-600" />
                <div>
                  <div className="text-2xl font-bold">
                    ${(metricsData.cost.total / 1000000).toFixed(1)}M
                  </div>
                  <div className="text-sm text-gray-500">Total Cost Savings</div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <div className="text-2xl font-bold">{metricsData.cost.opportunities}</div>
                <div className="text-sm text-gray-500">Cost Opportunities</div>
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Cost Drivers Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={costData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label
                  >
                    {costData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Cost Opportunities by Workstream</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Workstream</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Category</TableHead>
                  <TableHead className="text-right">Cost Savings</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {costTableData.map(row => (
                  <TableRow key={row.id}>
                    <TableCell className="font-medium">
                      {accountId ? (
                        <Link
                          to={`/accounts/${accountId}/workload/default?board=${row.id}`}
                          className="flex items-center hover:underline text-blue-600"
                        >
                          {row.name}
                          <ExternalLink className="ml-1 h-3 w-3" />
                        </Link>
                      ) : (
                        <span>{row.name}</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Badge variant={row.status === 'Realized' ? 'success' : 'secondary'}>
                        {row.status}
                      </Badge>
                    </TableCell>
                    <TableCell>{row.driver === 'N/A' ? 'Not Available' : row.driver}</TableCell>
                    <TableCell className="text-right font-bold">
                      {formatRevenue(row.value)}
                    </TableCell>
                  </TableRow>
                ))}
                {costTableData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} className="text-center py-4 text-gray-500">
                      No cost opportunities found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderRiskContent = () => {
    const riskData = [
      { name: 'Critical', value: metricsData.risk.critical },
      { name: 'High', value: metricsData.risk.high },
      { name: 'Medium', value: metricsData.risk.medium },
      { name: 'Low', value: metricsData.risk.low },
    ];

    const riskTableData = metrics
      .filter(metric => metric.riskSeverity)
      .map(metric => {
        let workstreamName = 'Unnamed Workstream';
        if (boardNames[metric.boardId]) {
          workstreamName = boardNames[metric.boardId];
        } else if (metric.boardName) {
          workstreamName = metric.boardName;
        } else if (metric.workloadName) {
          workstreamName = metric.workloadName;
        } else if (metric.board && Array.isArray(metric.board) && metric.board[0]?.name) {
          workstreamName = metric.board[0].name;
        }

        return {
          id: metric.boardId || metric._id,
          name: workstreamName,
          status: metric.status || 'Potential',
          severity: metric.riskSeverity,
          type: 'risk',
        };
      })
      .sort((a, b) => {
        const severityOrder = { critical: 1, high: 2, medium: 3, low: 4 };
        return severityOrder[a.severity] - severityOrder[b.severity];
      });

    return (
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <Shield className="w-5 h-5 text-yellow-600" />
                <div>
                  <div className="text-2xl font-bold">{metricsData.risk.critical}</div>
                  <div className="text-sm text-gray-500">Critical Risks</div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-2">
                <div className="text-2xl font-bold">{metricsData.risk.high}</div>
                <div className="text-sm text-gray-500">High Risks</div>
              </div>
            </CardContent>
          </Card>
        </div>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Risk Severity Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={riskData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label
                  >
                    {riskData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <Card className="p-4">
          <CardHeader>
            <CardTitle>Risk Opportunities by Workstream</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Workstream</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Severity</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {riskTableData.map(row => (
                  <TableRow key={row.id}>
                    <TableCell className="font-medium">
                      {accountId ? (
                        <Link
                          to={`/accounts/${accountId}/workload/default?board=${row.id}`}
                          className="flex items-center hover:underline text-blue-600"
                        >
                          {row.name}
                          <ExternalLink className="ml-1 h-3 w-3" />
                        </Link>
                      ) : (
                        <span>{row.name}</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Badge variant={row.status === 'Realized' ? 'success' : 'secondary'}>
                        {row.status}
                      </Badge>
                    </TableCell>
                    <TableCell>{row.severity}</TableCell>
                  </TableRow>
                ))}
                {riskTableData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} className="text-center py-4 text-gray-500">
                      No risk opportunities found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl h-[90vh]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>

        <div className="overflow-y-auto flex-1">
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="mb-6">
              <TabsTrigger value="revenue" className="flex items-center gap-2">
                <TrendingUp className="w-4 h-4" />
                Revenue Growth
              </TabsTrigger>
              <TabsTrigger value="cost" className="flex items-center gap-2">
                <TrendingDown className="w-4 h-4" />
                Cost Reduction
              </TabsTrigger>
              <TabsTrigger value="risk" className="flex items-center gap-2">
                <Shield className="w-4 h-4" />
                Risk Analysis
              </TabsTrigger>
            </TabsList>

            <TabsContent value="revenue">{renderRevenueContent()}</TabsContent>

            <TabsContent value="cost">{renderCostContent()}</TabsContent>

            <TabsContent value="risk">{renderRiskContent()}</TabsContent>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MetricsDrillDownDialog;
