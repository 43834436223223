// src/components/AdminBanner.js
export const ADMIN_BANNER_HEIGHT = '2rem';

const AdminBanner = ({ isVisible = true }) => {
  if (!isVisible) return null;
  
  return (
    <div className="bg-red-600 text-white text-center py-2 font-bold fixed top-0 w-full z-50">
      ⚠️ You are logged in as Super Admin
    </div>
  );
};

export default AdminBanner;