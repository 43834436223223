// UserMenu.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { Button } from '../../components/ui/button';
import { Settings, LogOut, User as UserIcon } from 'lucide-react';
import UserAvatar from './UserAvatar';
import { useAuth } from "react-oidc-context";
import ProtectedRoute from '../auth/ProtectedRoute';
import { User } from 'lucide-react';

const UserMenu = ({ user }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const signOutRedirect = () => {
    auth.removeUser();
    sessionStorage.clear(); // Clear all session storage
    window.location.href = `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&logout_uri=${encodeURIComponent(process.env.REACT_APP_AWS_LOGOUT_URI)}`;
}

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-10 w-10 rounded-full">
          <UserAvatar user={user} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.name}</p>
            <p className="text-xs leading-none text-gray-500">{user?.email}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => navigate('/user/profile')}>
            <UserIcon className="mr-2 h-4 w-4" />
            Profile
          </DropdownMenuItem>
          <ProtectedRoute requiredRoles={['BelayAdmin', 'rbac_testGroup']}>
            <DropdownMenuItem onClick={() => navigate('/profile')}>
              <User className="mr-2 h-4 w-4" />
              <span>troubleshoot</span>
            </DropdownMenuItem>
          </ProtectedRoute>
          <DropdownMenuItem onClick={() => navigate('/user/settings')}>
            <Settings className="mr-2 h-4 w-4" />
            Settings
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={signOutRedirect}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Sign Out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserMenu;