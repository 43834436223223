import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/card';
import { MoreVertical, Trash2 } from 'lucide-react';
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { cn } from '../../lib/utils';
import { Badge } from '../../components/ui/badge';  

const getStatusColor = (status) => {
  const colors = {
    'Not_Started': 'bg-gray-100 text-gray-800',
    'In_Progress': 'bg-blue-100 text-blue-800',
    'Completed': 'bg-green-100 text-green-800',
    'Blocked': 'bg-red-100 text-red-800'
  };
  return colors[status] || colors['Not_Started'];
};

const getMilestoneColor = (milestones) => {
  if (!milestones?.length) return 'bg-gray-50';
  
  const hasBlocked = milestones.some(m => m.status === 'Blocked');
  const allComplete = milestones.every(m => m.status === 'Completed');
  const hasInProgress = milestones.some(m => m.status === 'In_Progress');
  
  if (hasBlocked) return 'bg-red-50';
  if (allComplete) return 'bg-green-50';
  if (hasInProgress) return 'bg-blue-50';
  return 'bg-gray-50';
};

const BoardCard = ({ board, onClick, onDelete, className }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
  };

  const handleDelete = async () => {
    await onDelete(board._id);
    setShowDeleteDialog(false);
  };

  const cardColorClass = getMilestoneColor(board.milestones);

  return (
    <>
     <Card 
      className={cn(
        'hover:shadow-md transition-all cursor-pointer group',
        cardColorClass,
        'border-gray-200',
        'hover:border-gray-300',
        className
      )}
      onClick={onClick}
    >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-lg font-semibold text-gray-900">
            {board.name}
          </CardTitle>
          <DropdownMenu>
            <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
              <button className="opacity-0 group-hover:opacity-100 p-1 hover:bg-gray-100 rounded">
                <MoreVertical className="h-4 w-4 text-gray-500" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                className="text-red-600 focus:text-red-600"
                onClick={handleDeleteClick}
              >
                <Trash2 className="mr-2 h-4 w-4" />
                Delete Board
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </CardHeader>
        <CardContent>
          <div className="text-sm text-gray-600">
            Created {new Date(board.createdAt).toLocaleDateString()}
          </div>
        </CardContent>
        {board.milestones?.length > 0 && (
        <div className="px-6 pb-4 flex flex-wrap gap-2">
          {board.milestones.map(milestone => (
            <Badge 
              key={milestone._id}
              className={getStatusColor(milestone.status)}
            >
              {milestone.name === 'Custom' 
                ? milestone.customName 
                : milestone.name.replace(/_/g, ' ')}
            </Badge>
          ))}
        </div>
      )}
      </Card>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Board</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete "{board.name}"? This action cannot be undone
              and all tasks within this board will be permanently deleted.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-red-600 hover:bg-red-700 focus:ring-red-600"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default BoardCard;