import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Card, CardContent } from './ui/card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { TrendingUp, TrendingDown, Shield } from 'lucide-react';
import { cn } from '../lib/utils';
import { metricsApi } from '../services/metricsApi';
import MetricsDrillDown from './BusinessCase/MetricsDrillDown';
import WorkstreamMetrics from './WorkstreamMetrics';
import CostReductionMetrics from './CostReductionMetrics';
import { logDebug } from '../utils/logger';

// Design system colors remain the same...
const CHART_COLORS = {
  brand: [
    'hsl(210 45% 64%)',  // Sky
    'hsl(16 47% 49%)',   // Desert Rock
    'hsl(39 50% 48%)',   // Autumn Leaf
    'hsl(210 60% 34%)',  // Alpine Lake
    'hsl(16 47% 69%)',   // Canyon
    'hsl(39 65% 73%)'    // Morning Sun
  ],
  risk: {
    critical: '#ef4444',
    high: '#f97316',
    medium: '#eab308',
    low: '#22c55e'
  }
};

// Keep ValueCard and ChartCard components the same...
const ValueCard = ({ title, total, realized, potential, description, icon: Icon, onClick }) => {
  const formattedTotal = typeof total === 'number' ? 
    `$${(total / 1000000).toFixed(1)}M` : 
    total;
    
  const formattedRealized = typeof realized === 'number' ? 
    `$${(realized / 1000000).toFixed(1)}M` : 
    realized;
    
  const formattedPotential = typeof potential === 'number' ? 
    `$${(potential / 1000000).toFixed(1)}M` : 
    potential;

  return (
    <Card 
      className={cn(
        "bg-white/95 backdrop-blur border-none shadow-lg transition-all",
        onClick && "hover:shadow-xl cursor-pointer"
      )}
      onClick={onClick}
    >
      <CardContent className="pt-6">
        <div className="flex items-center justify-between mb-4">
          <div className="p-2 rounded-full bg-brand-primary/10">
            <Icon className="w-5 h-5 text-brand-primary" />
          </div>
          {description && (
            <div className="text-sm text-muted-foreground">{description}</div>
          )}
        </div>
        <div className="space-y-4">
          <div>
            <div className="text-2xl font-bold text-foreground">{formattedTotal}</div>
            <div className="text-sm text-muted-foreground">{title}</div>
          </div>
          <div className="grid grid-cols-2 gap-4 pt-2 border-t">
            <div>
              <div className="text-sm font-medium text-green-600">{formattedRealized}</div>
              <div className="text-xs text-muted-foreground">Realized</div>
            </div>
            <div>
              <div className="text-sm font-medium text-blue-600">{formattedPotential}</div>
              <div className="text-xs text-muted-foreground">Potential</div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const ChartCard = ({ title, children, onClick }) => {
  return (
    <Card 
      className={cn(
        "p-4 transition-all",
        onClick && "hover:shadow-lg cursor-pointer"
      )}
      onClick={onClick}
    >
      <div className="font-semibold mb-4">{title}</div>
      <div className="h-64">
        {children}
      </div>
    </Card>
  );
};

const MetricsDashboard = ({ workloadMetrics, accountId }) => {
  const [showDrillDown, setShowDrillDown] = useState(false);
  const [selectedTab, setSelectedTab] = useState('revenue');

  // Fetch metrics summary from backend
  const { data: metricsSummary, isLoading: isSummaryLoading } = useQuery(
    ['metrics-summary', accountId],
    () => metricsApi.getStatusSummary(accountId),
    {
      enabled: !!accountId,
      staleTime: 30000 // Cache for 30 seconds
    }
  );

  console.log('Dashboard State:', {
    workloadMetrics: workloadMetrics?.length,
    accountId,
    isSummaryLoading,
    metricsSummary
  });

  // Early return if essential data is missing
  if (!accountId) {
    console.warn('MetricsDashboard: accountId is required');
    return null;
  }

  // Show loading state
  if (isSummaryLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-lg text-gray-500">Loading metrics...</div>
      </div>
    );
  }

  // Show empty state if no data
  if (!workloadMetrics?.length && !metricsSummary?.length) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <div className="text-lg text-gray-500 mb-2">No metrics available</div>
        <div className="text-sm text-gray-400">Add some metrics to see your dashboard</div>
      </div>
    );
  }

  // Process backend summary into our display format
  const processBackendSummary = (summary) => {
    if (!summary) return {
      revenue: { total: 0, realized: 0, potential: 0, opportunities: 0 },
      cost: { total: 0, realized: 0, potential: 0, opportunities: 0 },
      risk: { total: 0, critical: 0, high: 0, mitigated: 0, potential: 0 }
    };

    const getTypeMetrics = (type) => {
      const typeData = summary.find(m => m._id === type);
      if (!typeData) return { total: 0, realized: 0, potential: 0, opportunities: 0 };

      const realized = typeData.statuses.find(s => s.status === 'Realized') || { value: 0, count: 0 };
      const potential = typeData.statuses.find(s => s.status === 'Potential') || { value: 0, count: 0 };

      return {
        total: realized.value + potential.value,
        realized: realized.value,
        potential: potential.value,
        opportunities: realized.count + potential.count
      };
    };

    return {
      revenue: getTypeMetrics('revenue'),
      cost: getTypeMetrics('cost'),
      risk: {
        ...getTypeMetrics('risk'),
        critical: workloadMetrics.filter(m => m.type === 'risk' && m.riskSeverity === 'critical').length,
        high: workloadMetrics.filter(m => m.type === 'risk' && m.riskSeverity === 'high').length
      }
    };
  };

  const valueMetrics = processBackendSummary(metricsSummary);

  // Distribution calculations for charts
  const revenueDistribution = Object.entries(
    workloadMetrics
      .filter(m => m.type === 'revenue' && m.revenueDriver)
      .reduce((acc, metric) => {
        const key = metric.revenueDriver;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {})
  ).map(([key, value]) => ({
    name: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    value
  }));

  const costDistribution = Object.entries(
    workloadMetrics
      .filter(m => m.type === 'cost' && m.costDriver)
      .reduce((acc, metric) => {
        const key = metric.costDriver;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {})
  ).map(([key, value]) => ({
    name: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
    value
  }));

  //@TODO: Remove the two above functions if unused
  logDebug('Revenue Distribution:', revenueDistribution);
  logDebug('Cost Distribution:', costDistribution);

  const riskAreaData = workloadMetrics
    .filter(m => m.type === 'risk' && m.riskArea && m.riskSeverity)
    .reduce((acc, metric) => {
      const area = metric.riskArea.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      
      const existingArea = acc.find(item => item.name === area);
      if (existingArea) {
        existingArea[metric.riskSeverity] = (existingArea[metric.riskSeverity] || 0) + 1;
      } else {
        acc.push({
          name: area,
          critical: metric.riskSeverity === 'critical' ? 1 : 0,
          high: metric.riskSeverity === 'high' ? 1 : 0,
          medium: metric.riskSeverity === 'medium' ? 1 : 0,
          low: metric.riskSeverity === 'low' ? 1 : 0
        });
      }
      return acc;
    }, []);

  const handleCardClick = (tab) => {
    setSelectedTab(tab);
    setShowDrillDown(true);
  };

  return (
    <div className="space-y-6">
      {/* Enhanced Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <ValueCard
          title="Revenue Growth Potential"
          total={valueMetrics.revenue.total}
          realized={valueMetrics.revenue.realized}
          potential={valueMetrics.revenue.potential}
          description={`${valueMetrics.revenue.opportunities} opportunities`}
          icon={TrendingUp}
          onClick={() => handleCardClick('revenue')}
        />
        <ValueCard
          title="Cost Reduction Potential"
          total={valueMetrics.cost.total}
          realized={valueMetrics.cost.realized}
          potential={valueMetrics.cost.potential}
          description={`${valueMetrics.cost.opportunities} opportunities`}
          icon={TrendingDown}
          onClick={() => handleCardClick('cost')}
        />
        <ValueCard
          title="Risk Profile"
          total={valueMetrics.risk.total}
          realized={valueMetrics.risk.mitigated}
          potential={valueMetrics.risk.potential}
          description={`${valueMetrics.risk.critical} Critical, ${valueMetrics.risk.high} High Impact`}
          icon={Shield}
          onClick={() => handleCardClick('risk')}
        />
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <ChartCard 
  title="Revenue Growth Drivers"
  onClick={() => handleCardClick('revenue')}
>
  <WorkstreamMetrics accountId={accountId} />
</ChartCard>

<ChartCard 
        title="Cost Reduction Drivers"
        onClick={() => handleCardClick('cost')}
      >
        <CostReductionMetrics accountId={accountId} />
      </ChartCard>
      </div>
      {riskAreaData.length > 0 && (
        <ChartCard 
          title="Risk Profile by Area"
          onClick={() => handleCardClick('risk')}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={riskAreaData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="critical" stackId="a" fill={CHART_COLORS.risk.critical} />
              <Bar dataKey="high" stackId="a" fill={CHART_COLORS.risk.high} />
              <Bar dataKey="medium" stackId="a" fill={CHART_COLORS.risk.medium} />
              <Bar dataKey="low" stackId="a" fill={CHART_COLORS.risk.low} />
            </BarChart>
          </ResponsiveContainer>
        </ChartCard>
      )}

      {/* Drill Down Dialog */}
      <MetricsDrillDown
        isOpen={showDrillDown}
        onClose={() => setShowDrillDown(false)}
        metrics={workloadMetrics}
        initialTab={selectedTab}
      />
    </div>
  );
};

export default MetricsDashboard;