import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import BoardPage from './pages/BoardPage';
import TasksPage from './pages/TasksPage';
import Home from './pages/Home';
import UnauthLanding from './pages/UnauthLanding';
import PrivacyPage from './pages/PrivacyPage';
import AccountPage from './pages/AccountPage';
import LeftSidebar from './components/LeftSidebar';
import AdminBanner from './components/AdminBanner';
import TemplatesPage from './pages/TemplatesPage';
import TitleManager from './components/TitleManager';
import TemplateCreator from './pages/TemplateCreator';
import TermsPage from './pages/TermsPage';
import UserPages from './pages/UserPages';
import LoadingScreen from './components/LoadingScreen';
import UserMenu from './components/user/UserMenu';
import UserProfile from './components/UserProfile';
import ProtectedRoute from './components/auth/ProtectedRoute';
import SilentRenew from './pages/SilentRenew';
import { useAuth } from "react-oidc-context";
import AdminMenu from './components/AdminMenu.js';
import AccountAccess from './pages/AdminPages/AccountAccess';
import RoleManagement from './pages/AdminPages/RoleManagement';
import ActivateUsers from './pages/AdminPages/ActivateUsers';
import APIKeys from './pages/AdminPages/APIKeys';
import { cn } from "./lib/utils";
import { Toaster } from "./components/ui/toaster"
import useCheckAuth from './hooks/useCheckAuth';
import 'reactflow/dist/style.css';
import './App.css';
import tokenService from './services/tokenService';
import ReactGA from "react-ga4";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

// Component for the main layout with sidebar
const MainLayout = ({ children }) => {
  const auth = useAuth();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  return (
    <div className="min-h-screen">
      <nav id="headerbar" className={cn(
        "bg-white shadow-rei fixed w-full z-50 pt-3 h-16"
      )}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-full">
            <div className="flex items-center">
              <Link to="/">
                <img 
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  alt="Logo"
                  className="h-8 w-auto"
                />
              </Link>
            </div>
            
            <div className="flex items-center space-x-4">
              <AdminMenu />
              <UserMenu user={{
                name: auth.isAuthenticated ? auth.user?.profile.name?.toString() : null,
                email: auth.isAuthenticated ? auth.user?.profile.email : null
              }} />
            </div>
          </div>
        </div>
      </nav>

      <div className={cn(
        "flex relative pt-16"
      )}>
        <LeftSidebar 
          isCollapsed={sidebarCollapsed} 
          onCollapse={handleCollapse}
        />
        <main className={cn(
          "flex-1 transition-all duration-300",
          "min-h-[calc(100vh-4rem)]",
          "lg:ml-0"
        )}>
          <div className="p-6">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

// Create a PageTracker component
const PageTracker = () => {
  const location = useLocation();
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

function App() {
  const auth = useAuth();
  
  // Make auth instance available to API interceptor and manage tokens
  useEffect(() => {
    window._currentAuth = auth;
    
    if (auth.isAuthenticated && auth.user?.access_token) {
      tokenService.setToken(auth.user.access_token);
    } else {
      tokenService.clearToken();
    }
    
    return () => {
      delete window._currentAuth;
      tokenService.clearToken();
    };
  }, [auth, auth.isAuthenticated, auth.user?.access_token]);

  const { hasAccess: isBelayUser } = useCheckAuth('BelayUsers');
  const { hasAccess: isBelayAdmin } = useCheckAuth('BelayAdmin');

  const signOutRedirect = () => {
    auth.removeUser();
    sessionStorage.clear(); // Clear all session storage
    window.location.href = `https://belay.auth.us-east-1.amazoncognito.com/logout?client_id=5r6d2aap07bsm45l93qpu0hjv7&logout_uri=${encodeURIComponent(process.env.REACT_APP_AWS_LOGOUT_URI)}`;
}

if (auth.isLoading) {
  return <LoadingScreen />;
}

  if (auth.error) {
    if (auth.error.message === 'invalid_grant') {
      auth.signinRedirect();
    }
    return <div>Encountering error... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated || !isBelayUser) {
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
        <TitleManager />
          {auth.isAuthenticated && !isBelayUser ? (
            // Show unauthorized message if authenticated but not a Belay user
            <div className="min-h-screen">
              <nav className="bg-white shadow-rei fixed w-full z-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex justify-between h-16">
                    <div className="flex items-center">
                      <Link to="/">
                        <img 
                          src={`${process.env.PUBLIC_URL}/logo.png`}
                          alt="Logo"
                          className="h-8 w-auto"
                        />
                      </Link>
                    </div>
                    <div className="flex items-center">
                      <button 
                        onClick={signOutRedirect}
                        className="text-gray-600 hover:text-gray-900"
                      >
                        Sign out
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-between h-16">
                    <div className="flex items-center">
                      Your account access to this application has not been authorized or is suspended. 
                      Please contact your administrator.
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          ) : (
            // Show landing page for unauthenticated users
            <Routes>
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="*" element={<UnauthLanding />} />

          </Routes>
          )}
        </Router>
      </QueryClientProvider>
    );
  }

  if (auth.isAuthenticated && isBelayUser) {
    // Remove old token storage code and just render the app
    return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <PageTracker /> {/* Add this line right after Router */}
      {isBelayAdmin && <AdminBanner />}
      <div className={`${isBelayAdmin ? 'mt-10' : ''}`}>
        <div className="min-h-screen bg-gray-50">
          <Routes>
            
            {/* All other routes - with sidebar */}
            <Route path="*" element={
              <MainLayout>
                <Routes>
                  {/* Home and Template routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="/templates" element={<TemplatesPage />} />
                  <Route path="/templates/new" element={<TemplateCreator />} />
                  <Route path="/templates/edit/:templateId" element={<TemplateCreator />} />
                  
                  {/* User routes */}
                  <Route path="/user/*" element={<UserPages />} />
                  
                  {/* User routes */}
                  <Route path="/user/*" element={<UserPages />} />
                  
                  {/* Account dashboard */}
                  <Route path="/accounts/*" element={<AccountPage />} />
                  <Route path="/accounts/:accountId/*" element={<AccountPage />} />
                  {/* Add these new routes for TasksPage */}
                  <Route path="/accounts/:accountId/tasks/:type" element={<TasksPage />} />
                  <Route path="/accounts/:accountId/tasks/:type/:boardId" element={<TasksPage />} />
                  {/* Board routes - now flat */}
                  <Route path="/accounts/:accountId/board/all" element={<BoardPage />} />
                  <Route path="/accounts/:accountId/board/business" element={<BoardPage />} />
                  <Route path="/accounts/:accountId/board/enablement" element={<BoardPage />} />
                  <Route path="/accounts/:accountId/workload/:workloadId" element={<BoardPage />} />
                  
                  {/* Settings and Help pages */}
                  <Route 
                    path="/accounts/:accountId/settings" 
                    element={
                      <div className="p-4">
                        <h1 className="text-2xl font-bold mb-4">Settings</h1>
                        <p className="text-gray-500">Settings page content coming soon...</p>
                      </div>
                    } 
                  />
                  <Route 
                    path="/accounts/:accountId/help" 
                    element={
                      <div className="p-4">
                        <h1 className="text-2xl font-bold mb-4">Help & Support</h1>
                        <p className="text-gray-500">Help and support content coming soon...</p>
                      </div>
                    } 
                  />
                  {/* Admin routes */}
                  
                  <Route 
                      path="/admin/accounts" 
                      element={
                        <ProtectedRoute requiredRoles={['rbac_OrgAdmin']}>
                          <AccountAccess />
                         </ProtectedRoute>

                      } 
                  />
                  
                  <Route 
                      path="/admin/roles" 
                      element={
                        <ProtectedRoute requiredRoles={['rbac_OrgAdmin']}>
                          <RoleManagement />
                        </ProtectedRoute>
                      } 
                  />
                  <Route 
                      path="/admin/activate-users" 
                      element={
                        <ProtectedRoute requiredRoles={['rbac_OrgAdmin']}>
                          <ActivateUsers />
                        </ProtectedRoute>
                      } 
                  />
                   <Route 
                      path="/admin/api-keys" 
                      element={
                        <ProtectedRoute requiredRoles={['rbac_OrgAdmin']}>
                          <APIKeys />
                        </ProtectedRoute>
                      } 
                  />
                  {/* Other routes */}
                  <Route path="/profile" element={<UserProfile />} />
                  {/* Catch all */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                  <Route path="/silent-renew" element={<SilentRenew />} />
                  <Route path="/terms" element={<TermsPage />} />
                </Routes>
              </MainLayout>
            } />
          </Routes>
          <Toaster />
        </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
  }
}

export default App;