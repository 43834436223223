import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { accountApi } from '../services/api';

const TitleManager = () => {
  const location = useLocation();
  const accountIdMatch = location.pathname.match(/\/accounts\/([^/]+)/);
  const accountId = accountIdMatch ? accountIdMatch[1] : null;

  // Use a longer staleTime to prevent unnecessary refetches
  const { 
    data: account, 
    isLoading, 
    error,
    isError 
  } = useQuery(
    ['account', accountId],
    () => accountApi.getAccountById(accountId),
    {
      enabled: !!accountId,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      retry: 2,
      onError: (err) => {
        console.error('TitleManager: Query error:', {
          message: err.message,
          response: err.response?.data,
          status: err.response?.status
        });
      },
      select: (data) => {
        // Ensure we have the data structure we expect
        console.log('TitleManager: Processing account data:', data);
        if (!data || typeof data !== 'object') {
          console.warn('TitleManager: Invalid account data received:', data);
          return null;
        }
        return data;
      }
    }
  );

  useEffect(() => {
    const baseTitle = 'Belay Consumption CRM';
    let newTitle = baseTitle;

    if (accountId) {
      if (isLoading) {
        newTitle = `Loading... - ${baseTitle}`;
      } else if (isError) {
        console.error('TitleManager: Error setting title:', error);
        newTitle = `Error - ${baseTitle}`;
      } else if (account?.name) {
        newTitle = `${account.name} - ${baseTitle}`;
      } else {
        console.warn('TitleManager: No account name available for:', accountId);
      }
    }

    console.log('TitleManager: Updating title to:', newTitle);
    document.title = newTitle;

    return () => {
      document.title = baseTitle;
    };
  }, [account?.name, accountId, isLoading, isError, error]);

  // Debug output state
  console.log('TitleManager: Current state:', {
    accountId,
    hasAccount: !!account,
    accountName: account?.name,
    isLoading,
    isError,
    error: error?.message,
    pathname: location.pathname
  });

  return null;
};

export default TitleManager;