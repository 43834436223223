// src/services/api.js
import axios from 'axios';
import { logDebug } from '../utils/logger';
import { refreshTokenSilently } from '../utils/auth';
import tokenService from './tokenService';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth token to requests
api.interceptors.request.use((config) => {
  const token = tokenService.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const currentAccountId = localStorage.getItem('currentAccountId');
  logDebug('FE Current account ID:', currentAccountId);
  if (currentAccountId) {
    config.headers['x-account-id'] = currentAccountId;
  }
  return config;
});

// Handle response errors
api.interceptors.response.use(
  async (response) => {
    // Check for auth refresh header
    if (response.headers['x-auth-refresh'] === 'true') {
      try {
        const auth = window._currentAuth;
        const newToken = await refreshTokenSilently(auth);
        tokenService.setToken(newToken);
      } catch (error) {
        console.error('Failed to refresh token after group change:', error);
      }
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    // Check for specific token refresh error
    if (
      error.response?.status === 401 && 
      error.response?.data?.error === 'TOKEN_REFRESH' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      
      // Handle concurrent refresh requests
      let refreshPromise = tokenService.getRefreshPromise();
      
      if (!refreshPromise) {
        refreshPromise = refreshTokenSilently(window._currentAuth);
        tokenService.setRefreshPromise(refreshPromise);
        
        try {
          const newToken = await refreshPromise;
          tokenService.setToken(newToken);
        } finally {
          tokenService.setRefreshPromise(null);
        }
      } else {
        await refreshPromise;
      }
      
      originalRequest.headers.Authorization = `Bearer ${tokenService.getToken()}`;
      return api(originalRequest);
    }
    
    if (error.response?.status === 401) {
      tokenService.clearToken();
      window.location.href = '/login';
    }
    
    return Promise.reject(error);
  }
);

// Admin APIs
export const CognitoApi = {
  listGroups: async () => {
    const response = await api.get('/admin/groups');
    return response.data;
  },

  listGroupUsers: async (groupId) => {
    const response = await api.get(`/admin/groups/${groupId}/users`);
    return response.data;
  },

  listAvailableUsers: async (groupId) => {
    const response = await api.get('/admin/users/available', { params: { groupId } });
    return response.data;
  },

  addUsertoGroup: async (groupId, userId) => {
    await api.post(`/admin/groups/${groupId}/users`, { userId });
  },

  removeUserFromGroup: async (groupId, userId) => {
    await api.delete(`/admin/groups/${groupId}/users/${userId}`);
  },

  createGroup: async (groupName) => {
    await api.post('/admin/groups', { groupName });
  },

  deleteGroup: async (groupId) => {
    const response = await api.delete(`/admin/groups/${groupId}`);
    return response.data;
  }
};

// Task APIs
export const taskApi = {
  getAllTasks: async (filters = {}) => {
    const response = await api.get('/tasks', { params: filters });
    return response.data;
  },

  getTaskById: async (id) => {
    const response = await api.get(`/tasks/${id}`);
    return response.data;
  },

  createTask: async (taskData) => {
    const response = await api.post('/tasks', taskData);
    return response.data;
  },

  updateTask: async (id, updates) => {
    // Make sure the id is converted to string if needed
    const taskId = String(id);
    const response = await api.patch(`/tasks/${taskId}`, updates);
    return {
      ...response.data,
      _id: String(response.data._id)  // Ensure ID is string
    };
    // Make sure the id is converted to string if needed
  },

  deleteTask: async (id) => {
    try {
      await api.delete(`/tasks/${id}`);
      return true; // Return something to indicate success
    } catch (error) {
      console.error('API Error deleting task:', error);
      throw error;
    }
  },

  getTaskHistory: async (id) => {
    const response = await api.get(`/tasks/${id}/history`);
    return response.data;
  }
};

// Board APIs
export const boardApi = {
  getAllBoards: async (filters = {}) => {
    
    try {
      const response = await api.get('/boards', { 
        params: {
          accountId: filters.accountId,
          type: filters.type
        }
      });
      return response.data;
    } catch (error) {
      console.error('Boards API error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      throw error;
    }
  },

  getBoardById: async (id) => {
    const response = await api.get(`/boards/${id}`);
    return response.data;
  },

  createBoard: async (boardData) => {
    const response = await api.post('/boards', boardData);
    return response.data;
  },

  updateBoard: async (id, updates) => {
    const response = await api.patch(`/boards/${id}`, updates);
    return response.data;
  },

  deleteBoard: async (id) => {
    await api.delete(`/boards/${id}`);
  },

  deleteColumn: async (boardId, columnId) => {
    await api.delete(`/boards/${boardId}/columns/${columnId}`);
  },

  updateColumnTitle: async (boardId, columnId, newTitle) => {
    logDebug('Making API call to update column title:', {
      boardId,
      columnId,
      newTitle
    });
    
    const response = await api.patch(`/boards/${boardId}/columns/${columnId}`, {
      title: newTitle
    });
    return response.data;
  },

  addTaskToBoard: async (boardId, taskId, position) => {
    const response = await api.post(`/boards/${boardId}/tasks`, { taskId, position });
    return response.data;
  },

  updateTaskPosition: async (boardId, taskId, position) => {
    const response = await api.patch(`/boards/${boardId}/tasks/${taskId}/position`, { position });
    return response.data;
  },

  removeTaskFromBoard: async (boardId, taskId) => {
    await api.delete(`/boards/${boardId}/tasks/${taskId}`);
  },

  checkMaturity: async (boardId) => {
    try {
      logDebug('Checking maturity for board:', boardId);
      const response = await api.get(`/boards/${boardId}/maturity`);
      return response.data;
    } catch (error) {
      console.error('Error checking board maturity:', error);
      throw error;
    }
  },

  levelUp: async (boardId, options = {}) => {
    try {
      logDebug('Leveling up board:', boardId, 'with options:', options);
      const response = await api.post(`/boards/${boardId}/level-up`, {
        selectedPath: options.selectedPath,
        notes: options.notes
      });
      return response.data;
    } catch (error) {
      console.error('Error leveling up board:', error);
      throw error;
    }
  },

  getMilestones: async (boardId) => {
    try {
      const response = await api.get(`/boards/${boardId}/milestones`);
      return response.data;
    } catch (error) {
      console.error('Error fetching milestones:', error);
      throw error;
    }
  },

  addMilestone: async (boardId, milestoneData) => {
    try {
      logDebug('Adding milestone:', { boardId, milestoneData });
      const response = await api.post(`/boards/${boardId}/milestones`, milestoneData);
      return response.data;
    } catch (error) {
      console.error('Error adding milestone:', error);
      throw error;
    }
  },

  updateMilestone: async (boardId, milestoneId, updates) => {
    try {
      logDebug('Updating milestone:', { boardId, milestoneId, updates });
      const response = await api.patch(
        `/boards/${boardId}/milestones/${milestoneId}`, 
        updates
      );
      return response.data;
    } catch (error) {
      console.error('Error updating milestone:', error);
      throw error;
    }
  },

  deleteMilestone: async (boardId, milestoneId) => {
    try {
      await api.delete(`/boards/${boardId}/milestones/${milestoneId}`);
    } catch (error) {
      console.error('Error deleting milestone:', error);
      throw error;
    }
  },

  updateMilestoneStatus: async (boardId, milestoneId, status) => {
    try {
      logDebug('Updating milestone status:', { boardId, milestoneId, status });
      const response = await api.patch(
        `/boards/${boardId}/milestones/${milestoneId}/status`,
        { status }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating milestone status:', error);
      throw error;
    }
  },

  linkTaskToMilestone: async (boardId, milestoneId, taskId) => {
    try {
      const response = await api.post(
        `/boards/${boardId}/milestones/${milestoneId}/tasks`,
        { taskId }
      );
      return response.data;
    } catch (error) {
      console.error('Error linking task to milestone:', error);
      throw error;
    }
  },

  unlinkTaskFromMilestone: async (boardId, milestoneId, taskId) => {
    try {
      await api.delete(
        `/boards/${boardId}/milestones/${milestoneId}/tasks/${taskId}`
      );
    } catch (error) {
      console.error('Error unlinking task from milestone:', error);
      throw error;
    }
  }
};

// Account APIs
export const accountApi = {
  getCurrentAccount: async () => {
    const response = await api.get('/accounts/current');
    return response.data;
  },
  
  getAccounts: async () => {
    const response = await api.get('/accounts');
    return response.data;
  },

  getAccountById: async (id) => {
    if (!id) throw new Error('Account ID is required');
    const response = await api.get(`/accounts/${id}`);
    return response.data;
  },

  getBoardsByAccount: async (accountId) => {
    const response = await api.get('/boards', { 
      params: { accountId }
    });
    return response.data;
  },

  createAccount: async (accountData) => {
    const response = await api.post('/accounts', accountData);
    return response.data;
  },

  updateAccount: async (id, updates) => {
    const response = await api.patch(`/accounts/${id}`, updates);
    return response.data;
  },

  deleteAccount: async (id) => {
    await api.delete(`/accounts/${id}`);
  },

  // User management within accounts
  inviteUser: async (accountId, userData) => {
    const response = await api.post(`/accounts/${accountId}/users`, userData);
    return response.data;
  },

  removeUser: async (accountId, userId) => {
    await api.delete(`/accounts/${accountId}/users/${userId}`);
  },

  updateUserRole: async (accountId, userId, role) => {
    const response = await api.patch(`/accounts/${accountId}/users/${userId}`, { role });
    return response.data;
  },

  // Account settings
  getAccountSettings: async (accountId) => {
    const response = await api.get(`/accounts/${accountId}/settings`);
    return response.data;
  },

  updateAccountSettings: async (accountId, settings) => {
    const response = await api.patch(`/accounts/${accountId}/settings`, settings);
    return response.data;
  },

  // Account statistics
  getAccountStats: async (accountId) => {
    const response = await api.get(`/accounts/${accountId}/stats`);
    return response.data;
  },

  // Account activity
  getAccountActivity: async (accountId, params = {}) => {
    const response = await api.get(`/accounts/${accountId}/activity`, { params });
    return response.data;
  },

  // Switch current account
  switchAccount: async (accountId) => {
    localStorage.setItem('currentAccountId', accountId);
    window.dispatchEvent(new CustomEvent('accountChanged', { 
      detail: { accountId } 
    }));
  },

  getAccountNames: async (ids) => {
    const response = await api.post('/accounts/names', { ids });
    return response.data;
  },

  async getSubAccounts(accountId) {
    const response = await api.get(`/accounts/${accountId}/sub-accounts`);
    return response.data;
  }
};

//AI API Calls
export const aiApi = {
  // Send message to AI chat
  sendMessage: async (message, contextData) => {
    const response = await api.post('/ai/chat', {
      message,
      ...contextData
    });
    return response.data;
  },

  // Get chat history
  getChatHistory: async (accountId) => {
    const response = await api.get('/ai/chat/history', {
      params: { accountId }
    });
    return response.data;
  },

  // Clear chat history
  clearHistory: async (accountId) => {
    const response = await api.delete('/ai/chat/history', {
      params: { accountId }
    });
    return response.data;
  }
};

export const businessCaseApi = {
  getBusinessCase: async (boardId) => {
    const response = await api.get(`/boards/${boardId}/business-case`);
    return response.data;
  },

  updateBusinessCase: async (boardId, updates) => {
    // Changed from PATCH to PUT since the backend route uses PUT
    const response = await api.put(`/boards/${boardId}/business-case`, updates);
    return response.data;
  },

  // Keep other methods but update their paths to match the backend routes
  addCustomField: async (boardId, fieldData) => {
    const response = await api.post(`/boards/${boardId}/business-case/fields`, fieldData);
    return response.data;
  },
  
  updateCustomField: async (boardId, fieldKey, updates) => {
    const response = await api.patch(`/boards/${boardId}/business-case/fields/${fieldKey}`, updates);
    return response.data;
  },
  
  deleteCustomField: async (boardId, fieldKey) => {
    await api.delete(`/boards/${boardId}/business-case/fields/${fieldKey}`);
  },
  
  setFieldValue: async (boardId, fieldKey, value) => {
    const response = await api.post(`/boards/${boardId}/business-case/fields/${fieldKey}/value`, { value });
    return response.data;
  }
};

// Template APIs
export const templateApi = {
  getAllTemplates: async (filters = {}) => {
    const response = await api.get('/templates', { params: filters });
    return response.data;
  },

  getTemplateById: async (id) => {
    const response = await api.get(`/templates/${id}`);
    return response.data;
  },

  createTemplate: async (templateData) => {
    const response = await api.post('/templates', {
      ...templateData,
      isActive: true // Default to active
    });
    return response.data;
  },

  // Get maturity flow data
  getMaturityFlow: async () => {
    const response = await api.get('/templates/maturity-flow');
    return response.data;
  },

  // Update maturity path connections
  updateMaturityConnections: async (templateId, connections) => {
    const response = await api.patch(`/templates/${templateId}/maturity-path`, {
      nextLevelOptions: connections.map(conn => ({
        template: conn.to,
        name: conn.label,
        description: 'Added via flow editor'
      }))
    });
    return response.data;
  },

  updateTemplate: async (id, updates) => {
    try {
      const response = await api.patch(`/templates/${id}`, updates);
      
      // Validate the response
      if (!response.data.defaultTasks) {
        console.warn('Template update succeeded but defaultTasks is empty:', response.data);
      }
      
      return response.data;
    } catch (error) {
      console.error('Error updating template:', error);
      console.error('Update payload:', updates);
      throw error;
    }
  },

  deleteTemplate: async (id) => {
    await api.delete(`/templates/${id}`);
  },

  // Create a board from a template
  createBoardFromTemplate: async (templateId, boardData) => {
    if (!templateId || !boardData.accountId || !boardData.name) {
      console.error('Missing required data:', { templateId, boardData });
      throw new Error('Missing required data for board creation');
    }
    
    logDebug('Making template board request:', {
      url: `/templates/${templateId}/boards`,
      data: boardData
    });
    
    const response = await api.post(`/templates/${templateId}/boards`, boardData);
    return response.data;
  },

  // Get all boards created from a template
  getTemplateBoards: async (templateId) => {
    const response = await api.get(`/templates/${templateId}/boards`);
    return response.data;
  }
};

// API Key Management APIs
export const apiKeyApi = {
  generateKey: async (name) => {
    const response = await api.post('/keys/generate', { name });
    return response.data;
  },

  listKeys: async () => {
    const response = await api.get('/keys/list');
    return response.data;
  },

  revokeKey: async (key) => {
    const response = await api.post('/keys/revoke', { key });
    return response.data;
  }
};

// Add account switching utility function
export const getCurrentAccountId = () => {
  return localStorage.getItem('currentAccountId');
};


export default api;