import React, { useState, useCallback, useMemo, useEffect } from 'react';
import ReactFlow, {
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  Position,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Handle,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { Save, X } from 'lucide-react';

// Dagre graph configuration
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const NODE_WIDTH = 250;
const NODE_HEIGHT = 100;
const LEVEL_SEPARATION = 300;

const getLayoutedElements = (nodes, edges) => {
  dagreGraph.setGraph({
    rankdir: 'LR',
    nodesep: 70,
    ranksep: LEVEL_SEPARATION,
    align: 'DL',
    ranker: 'network-simplex',
  });

  // Add nodes to dagre
  nodes.forEach(node => {
    dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
  });

  // Add edges to dagre
  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Calculate layout
  dagre.layout(dagreGraph);

  // Retrieve positions
  const layoutedNodes = nodes.map(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - NODE_WIDTH / 2,
        y: nodeWithPosition.y - NODE_HEIGHT / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

const TemplateNode = ({ data }) => {
  const levelColorMap = {
    1: 'bg-blue-50 border-blue-200',
    2: 'bg-green-50 border-green-200',
    3: 'bg-purple-50 border-purple-200',
    4: 'bg-amber-50 border-amber-200',
    5: 'bg-red-50 border-red-200',
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        className="w-4 h-4 !bg-red-500" // Added !bg-red-500 to override default style
      />
      <div
        className={`p-4 rounded-lg border-2 shadow-sm
          ${levelColorMap[data.template.maturityPath?.level] || 'bg-white border-gray-200'}`}
      >
        <div className="space-y-2">
          {/* Made the title more prominent */}
          <h3 className="font-semibold text-base">{data.template.name}</h3>

          {/* Added badges below the title */}
          <div className="flex flex-wrap gap-1">
            <Badge variant="secondary">Level {data.template.maturityPath?.level}</Badge>
            {data.template.maturityPath?.nextLevelOptions?.length > 0 && (
              <Badge variant="outline" className="text-xs">
                {data.template.maturityPath.nextLevelOptions.length} outgoing paths
              </Badge>
            )}
            {data.incomingPaths > 0 && (
              <Badge variant="outline" className="text-xs">
                {data.incomingPaths} incoming paths
              </Badge>
            )}
          </div>

          {/* Made description more visible */}
          {data.template.description && (
            <p className="text-sm text-gray-600">{data.template.description}</p>
          )}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        className="w-4 h-4 !bg-red-500" // Added !bg-red-500 to override default style
      />
    </>
  );
};

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, data, selected }) => {
  const pathColor = '#EF4444'; // Red color
  const [isHovered, setIsHovered] = useState(false);

  // Calculate the path
  const xOffset = Math.abs(targetX - sourceX) * 0.15;
  const path = `M ${sourceX} ${sourceY}
                  C ${sourceX + xOffset} ${sourceY},
                    ${targetX - xOffset} ${targetY},
                    ${targetX} ${targetY}`;

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path transition-all duration-300"
        d={path}
        strokeWidth={isHovered ? 4 : 3} // Made line thicker, gets even thicker on hover
        stroke={pathColor}
        fill="none"
        markerEnd={`url(#${id}-arrow)`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          cursor: 'pointer',
          filter: isHovered ? 'drop-shadow(0 0 3px rgba(239, 68, 68, 0.4))' : 'none', // Add glow effect on hover
        }}
      />
      {data?.label && (
        <text>
          <textPath
            href={`#${id}`}
            style={{
              fontSize: '12px',
              fontWeight: isHovered ? 'bold' : 'normal', // Make text bold on hover
            }}
            startOffset="50%"
            textAnchor="middle"
            dominantBaseline="text-after-edge"
            className={`fill-gray-600 ${isHovered ? 'font-semibold' : ''}`}
          >
            {data.label}
          </textPath>
        </text>
      )}
      <defs>
        <marker
          id={`${id}-arrow`}
          viewBox="0 0 10 10"
          refX="9"
          refY="5"
          markerWidth="12" // Made arrow bigger
          markerHeight="12" // Made arrow bigger
          orient="auto"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill={pathColor} />
        </marker>
      </defs>
    </>
  );
};

const Flow = ({ templates, onSave }) => {
  const { fitView } = useReactFlow();
  
  // Move createInitialElements before its usage
  const createInitialElements = useCallback(() => {
    const nodes = templates
      .filter(t => t.maturityPath?.isWorkloadTemplate)
      .map(template => ({
        id: template._id,
        type: 'templateNode',
        position: { x: 0, y: 0 },
        data: {
          template,
          incomingPaths: templates.filter(t =>
            t.maturityPath?.nextLevelOptions?.some(opt => opt.template === template._id)
          ).length,
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      }));

    const edges = templates.flatMap(
      template =>
        template.maturityPath?.nextLevelOptions?.map(option => ({
          id: `${template._id}-${option.template}`,
          source: template._id,
          target: option.template,
          type: 'custom',
          data: { label: option.name },
        })) || []
    );

    return getLayoutedElements(nodes, edges);
  }, [templates]);

  // Now we can use createInitialElements
  const { nodes: initialNodes, edges: initialEdges } = createInitialElements();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [hasChanges, setHasChanges] = useState(false);

  const onEdgeClick = useCallback((event, edge) => {
    setEdges(eds => eds.filter(e => e.id !== edge.id));
    setHasChanges(true);
  }, [setEdges]);

  // Define custom node and edge types
  const nodeTypes = useMemo(() => ({ templateNode: TemplateNode }), []);
  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

  // Handle new connections
  const onConnect = useCallback(
    params => {
      const sourceNode = nodes.find(n => n.id === params.source);
      const targetNode = nodes.find(n => n.id === params.target);

      if (sourceNode && targetNode) {
        const sourceLevel = sourceNode.data.template.maturityPath?.level;
        const targetLevel = targetNode.data.template.maturityPath?.level;

        if (targetLevel <= sourceLevel) {
          console.log('Invalid level connection');
          return;
        }

        setEdges(eds =>
          addEdge(
            {
              ...params,
              type: 'custom',
              data: {
                label: `${sourceNode.data.template.name} → ${targetNode.data.template.name}`,
              },
            },
            eds
          )
        );
        setHasChanges(true);
      }
    },
    [nodes, setEdges]
  );

  // Auto-layout on mount and when nodes/edges change
  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);

    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);

    setTimeout(() => {
      fitView({ padding: 0.2 });
    }, 0);
  }, [nodes, edges, setNodes, setEdges, fitView]);

  const handleReset = useCallback(() => {
    const { nodes: resetNodes, edges: resetEdges } = createInitialElements();
    setNodes(resetNodes);
    setEdges(resetEdges);
    setHasChanges(false);
    setTimeout(() => {
      fitView({ padding: 0.2 });
    }, 0);
  }, [createInitialElements, fitView, setNodes, setEdges]);

  const onEdgesDelete = useCallback(edgesToDelete => {
    setEdges(eds => eds.filter(e => !edgesToDelete.find(de => de.id === e.id)));
    setHasChanges(true);
  }, [setEdges]);

  const handleSave = () => {
    const updatedTemplates = templates.map(template => {
      const templateEdges = edges.filter(edge => edge.source === template._id);

      return {
        ...template,
        maturityPath: {
          ...template.maturityPath,
          nextLevelOptions: templateEdges.map(edge => ({
            template: edge.target,
            name: edge.data.label,
            description: 'Added via flow editor',
          })),
        },
      };
    });

    onSave(updatedTemplates);
    setHasChanges(false);
  };

  return (
    <Card className="mt-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Maturity Flow Editor</CardTitle>
        <div className="space-x-2">
          <Button variant="outline" size="sm" onClick={handleReset}>
            <X className="w-4 h-4 mr-2" />
            Reset
          </Button>
          <Button size="sm" onClick={handleSave} disabled={!hasChanges}>
            <Save className="w-4 h-4 mr-2" />
            Save Changes
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div style={{ height: 600 }} className="border rounded-lg bg-gray-50">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgesDelete={onEdgesDelete}
            onEdgeClick={onEdgeClick}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            // Add these props for keyboard deletion
            deleteKeyCode="Backspace"
            selectionKeyCode="Shift"
            fitView
            fitViewOptions={{ padding: 0.1 }}
            minZoom={0.5}
            maxZoom={1.5}
            defaultViewport={{ zoom: 1 }}
            attributionPosition="bottom-right"
            className="bg-gray-50"
            // Add these props for connection capabilities
            connectOnClick={true}
            connectionMode="loose"
            snapToGrid={true}
            snapGrid={[15, 15]}
          >
            <Background color="#CBD5E1" size={1} />
            <Controls />
          </ReactFlow>
        </div>
      </CardContent>
    </Card>
  );
};

const InteractiveMaturityFlow = ({ templates, onSave }) => {
  return (
    <ReactFlowProvider>
      <Flow templates={templates} onSave={onSave} />
    </ReactFlowProvider>
  );
};

export default InteractiveMaturityFlow;
