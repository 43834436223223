// src/components/ui/button.jsx
import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority"  // Add this import
import { cn } from "../../lib/utils"
import { colors } from "../../lib/theme"

export const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
  {
    variants: {
      variant: {
        default: cn(
          `bg-[${colors.brand.primary}]`,
          'text-white',
          `hover:bg-[${colors.background.secondary}]`,
          `hover:text-[${colors.brand.primary}]`,
          `border-[${colors.brand.primary}]`,
          'border'
        ),
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: cn(
          'border',
          `border-[${colors.brand.primary}]`,
          `text-[${colors.brand.primary}]`,
          `hover:bg-[${colors.background.secondary}]`,
          `hover:text-[${colors.brand.primary}]`
        ),
        secondary: cn(
          `bg-[${colors.background.secondary}]`,
          `text-[${colors.brand.primary}]`,
          `hover:bg-[${colors.brand.primary}]`,
          'hover:text-white'
        ),
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline text-primary",
      },
      // ...existing size variants...
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const Button = React.forwardRef(({
  className,
  variant = "default",
  size = "default",
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button"
  return (
    <Comp
      className={cn(
        "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        "disabled:pointer-events-none disabled:opacity-50",
        {
          "bg-primary text-primary-foreground hover:bg-primary/90": variant === "default",
          "bg-destructive text-destructive-foreground hover:bg-destructive/90": variant === "destructive",
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground": variant === "outline",
          "bg-secondary text-secondary-foreground hover:bg-secondary/80": variant === "secondary",
          "hover:bg-accent hover:text-accent-foreground": variant === "ghost",
          "bg-background hover:bg-accent hover:text-accent-foreground": variant === "link",
          "bg-rei-green-400 text-white hover:bg-rei-green-500": variant === "green",
          "bg-rei-blue-300 text-white hover:bg-rei-blue-400": variant === "blue",
          "bg-canyon-500 text-white hover:bg-canyon-600": variant === "canyon-light",
          "bg-canyon-600 text-white hover:bg-canyon-700": variant === "canyon-dark",
          "h-10 px-4 py-2": size === "default",
          "h-9 rounded-md px-3": size === "sm",
          "h-11 rounded-md px-8": size === "lg",
          "h-9 w-9": size === "icon",
        },
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Button.displayName = "Button"

export { Button }