// Column.js
import React, { useState, useRef, useEffect } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import Task from './Task';  
import { Badge } from '../../components/ui/badge';
import { cn } from '../../lib/utils';
import { MoreVertical, Trash2 } from 'lucide-react';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from '../../components/ui/dropdown-menu';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../components/ui/alert-dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { boardApi, taskApi } from '../../services/api';

const Column = ({ 
  droppableId, 
  title, 
  tasks, 
  boardId, 
  onColumnUpdate,
  onColumnDelete,
  onTaskUpdate,
  onTaskDelete,
  allColumns
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [targetColumn, setTargetColumn] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = async () => {
    if (editedTitle.trim() !== title) {
      try {
        await boardApi.updateColumnTitle(boardId, droppableId, editedTitle.trim());
        if (onColumnUpdate) {
          onColumnUpdate(droppableId, editedTitle.trim());
        }
      } catch (error) {
        console.error('Failed to update column title:', error);
        setEditedTitle(title);
      }
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleBlur();
    } else if (e.key === 'Escape') {
      setEditedTitle(title);
      setIsEditing(false);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (tasks.length > 0 && targetColumn) {
        // First move all tasks to the target column
        await Promise.all(tasks.map(task => 
          taskApi.updateTask(task._id, { status: targetColumn })  
        ));
      }
      
      // Then delete the column
      await boardApi.deleteColumn(boardId, droppableId);
      if (onColumnDelete) {
        onColumnDelete(droppableId);
      }
      setShowDeleteDialog(false);
    } catch (error) {
      console.error('Failed to delete column:', error);
    }
  };

  return (
    <div className="w-80 flex flex-col bg-white rounded-lg shadow">
      <div className="p-3 flex items-center justify-between">
        <div className="flex items-center flex-1">
          {isEditing ? (
            <input
              ref={inputRef}
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className="w-full px-2 py-1 text-sm font-medium border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          ) : (
            <div
              onDoubleClick={handleDoubleClick}
              className="text-sm font-medium cursor-pointer hover:text-blue-600"
            >
              {title}
            </div>
          )}
          <Badge variant="secondary" className="ml-2">
            {tasks.length}
          </Badge>
        </div>
  
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="p-1 hover:bg-gray-100 rounded">
              <MoreVertical className="h-4 w-4 text-gray-500" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              className="text-red-600"
              onClick={handleDeleteClick}
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete Column
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
  
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={cn(
              "flex-1 p-2 min-h-[200px] overflow-y-auto",
              snapshot.isDraggingOver && "bg-blue-50"
            )}
          >
            {tasks.map((task, index) => (
              <Task 
                key={task._id} 
                task={task} 
                index={index}
                onUpdate={onTaskUpdate}
                onDelete={onTaskDelete}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Column: {title}</AlertDialogTitle>
            <AlertDialogDescription>
              {tasks.length === 0 ? (
                'Are you sure you want to delete this column?'
              ) : (
                <>
                  <p className="mb-4">
                    This column contains {tasks.length} task{tasks.length === 1 ? '' : 's'}. 
                    Please select a column to move them to before deletion.
                  </p>
                  <Select
                    value={targetColumn}
                    onValueChange={setTargetColumn}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select a column" />
                    </SelectTrigger>
                    <SelectContent>
                      {allColumns
                        .filter(col => col.id !== droppableId)
                        .map(col => (
                          <SelectItem key={col.id} value={col.id}>
                            {col.title}
                          </SelectItem>
                        ))
                      }
                    </SelectContent>
                  </Select>
                </>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteConfirm}
              disabled={tasks.length > 0 && !targetColumn}
              className="bg-red-600 hover:bg-red-700 focus:ring-red-600"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Column;