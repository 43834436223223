import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { toast } from '../../components/ui/use-toast';
import { apiKeyApi } from '../../services/api';

const APIKeys = () => {
  const [keys, setKeys] = useState([]);
  const [newKeyName, setNewKeyName] = useState('');
  const [newKeyDetails, setNewKeyDetails] = useState(null);

  const loadKeys = async () => {
    try {
      const response = await apiKeyApi.listKeys();
      setKeys(response.keys);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error loading API keys",
        description: error.message
      });
    }
  };

  const generateKey = async () => {
    try {
      const response = await apiKeyApi.generateKey(newKeyName);
      setNewKeyDetails(response);
      setNewKeyName('');
      loadKeys();
      toast({
        title: "API Key Generated",
        description: "Please save your secret key now. It won't be shown again."
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error generating API key",
        description: error.message
      });
    }
  };

  const revokeKey = async (key) => {
    try {
      await apiKeyApi.revokeKey(key);
      loadKeys();
      toast({
        title: "API Key Revoked",
        description: "The API key has been successfully revoked."
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error revoking API key",
        description: error.message
      });
    }
  };

  useEffect(() => {
    loadKeys();
  }, []);

  return (
    <div className="container mx-auto py-6">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Generate New API Key</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex gap-4">
            <Input
              placeholder="Key Name"
              value={newKeyName}
              onChange={(e) => setNewKeyName(e.target.value)}
            />
            <Button onClick={generateKey} disabled={!newKeyName}>Generate</Button>
          </div>
          
          {newKeyDetails && (
            <div className="mt-4 p-4 bg-yellow-50 rounded-md">
              <p className="font-bold">Save these credentials immediately:</p>
              <p>Key: {newKeyDetails.key}</p>
              <p>Secret: {newKeyDetails.secret}</p>
            </div>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Active API Keys</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="divide-y">
            {keys.map((key) => (
              <div key={key.key} className="py-4 flex justify-between items-center">
                <div>
                  <p className="font-medium">{key.name}</p>
                  <p className="text-sm text-gray-500">Key: {key.key}</p>
                  <p className="text-sm text-gray-500">
                    Created: {new Date(key.createdAt).toLocaleDateString()}
                  </p>
                </div>
                <Button
                  variant="destructive"
                  onClick={() => revokeKey(key.key)}
                >
                  Revoke
                </Button>
              </div>
            ))}
            {keys.length === 0 && (
              <p className="text-gray-500">No active API keys</p>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default APIKeys;
