export const colors = {
  brand: {
    primary: '#1f513f',
    secondary: '#406eb5',
  },
  text: {
    primary: 'rgba(12, 11, 8, 0.75)',
    secondary: 'rgba(66, 59, 47, 0.75)',
    emphasis: '#20201d',
  },
  background: {
    primary: '#ffffff',
    secondary: '#f4f2ed',
    tertiary: '#f9f8f6',
  },
  border: {
    primary: '#dcd6cb',
    secondary: '#928b80',
  }
};
